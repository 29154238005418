import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BusinessSubject } from './../../../../_common/model/business-subject';
import { User } from './../../../../_common/model/user';
import { BaseState } from './../../../../_common/state/base/base.state';

@Component({
    selector: 'lay-men-header',
    templateUrl: './lay-men-header.component.html',
    styleUrls: ['./lay-men-header.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LayMenHeaderComponent implements OnInit {
    @Output()
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    onMenuSizeChange: EventEmitter<boolean> = new EventEmitter();

    public bs?: BusinessSubject = this.store.selectSnapshot(BaseState.getBS);
    public user?: User = this.store.selectSnapshot(BaseState.getUser);
    public isMin = false;
    private onDestroy$ = new Subject<void>();

    constructor(private store: Store) {}

    ngOnInit() {
        this.store
            .select(BaseState.getBS)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(bs => (this.bs = bs));
    }

    public minMenu(): void {
        this.isMin = !this.isMin;
        this.onMenuSizeChange.emit(this.isMin);
    }
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
