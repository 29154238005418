<div class="d-flex h-100">
    <div class="d-flex flex-column flex-grow-1">
        <div [ngClass]="{ 'container--sticky': selectedTab === 'COMMUNICATION' }">
            <ul class="nav nav-pills nav-justified">
                <!-- @TODO: Ignored manually on 2023-10-13 -->
                <!-- eslint-disable @angular-eslint/template/use-track-by-function -->
                <li
                    *ngFor="let tab of reservationsTabs"
                    (click)="selectTab(tab)"
                    class="nav-item mf-pointer font-weight-bold"
                    [ngClass]="{ active: selectedTab === tab }"
                >
                    <a class="nav-link h-100 font--small">{{ tab | tabTypeString | uppercase }}</a>
                </li>
                <!-- eslint-enable -->
            </ul>
        </div>
        <div [ngSwitch]="selectedTab" class="component_container flex-grow-1">
            <res-reservation-status *ngSwitchCase="'STATUS'" [reservationDetails]="reservationDetails"></res-reservation-status>
            <res-reservation-documents
                *ngSwitchCase="'REFERRAL'"
                [reservationDetails]="reservationDetails"
                [documentType]="'REFERRAL'"
                class="h-100"
            ></res-reservation-documents>
            <res-reservation-documents
                *ngSwitchCase="'REPORT'"
                [reservationDetails]="reservationDetails"
                [documentType]="'REPORT'"
                class="h-100"
            ></res-reservation-documents>
            <res-reservation-comm *ngSwitchCase="'COMMUNICATION'" [reservationDetails]="reservationDetails"></res-reservation-comm>
        </div>
    </div>
</div>
