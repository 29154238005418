import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CaseStatus } from '../../_common/enums/caseStatus.enum';
import { Comment } from '../../_common/model/comment';
import { CommunicationResponse } from '../../_common/model/communicationResponse.model';
import { Contractor } from '../../_common/model/contractor.model';
import { ApiUrlService } from '../../_common/services/api-url.service';
import { HttpClientService } from '../../_common/services/http-client.service';
import { ResDocument } from './model/res-document';
import { ReservationDetails } from './model/reservation-details';
import { ReservationList } from './model/reservation-list';
import { ReservationStatus } from './model/reservation-status';

@Injectable({
    providedIn: 'root',
})
export class ReservationsService {
    constructor(
        private httpClient: HttpClientService,
        private helperUrl: ApiUrlService,
    ) {}

    public getReservations(
        bsid: number,
        limit: number = 30,
        page: number = 0,
        orderBy: 'date' | 'caseid' | 'triageid' | 'customer' | 'contractor' | 'service' | 'appuser' = 'date',
        orderDirection: 0 | 1 = 1,
        // @TODO: Ignored with eslint-interactive on 2023-09-15
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        search: any,
        customerId: number | undefined,
        excludeStatus: string = 'NO_RESERVATIONS',
    ): Observable<{ result: ReservationList[]; resultSize: number }> {
        const offset = page * limit;

        let order = orderDirection === 0 ? '+' : '-';
        order += orderBy;
        order = encodeURIComponent(order);

        let searchString = '';

        for (const searchKey in search) {
            if (search[searchKey]) {
                searchString += '&' + searchKey + '=' + encodeURIComponent(search[searchKey]);
            }
        }
        let dataUrl = this.helperUrl.getAssistanceUrl(2) + `bs/${bsid}/cases-triages?limit=${limit}&offset=${offset}&order=${order}${searchString}`;
        if (customerId) dataUrl += `&customerid=${customerId}`;
        dataUrl += `&excludeCaseStatus=${excludeStatus}`;

        return this.httpClient.get(dataUrl);
    }

    public getSingleReservation(bsid: string | number, caseid: string): Observable<ReservationDetails[]> {
        return this.httpClient.get(
            this.helperUrl.getAssistanceUrl(2) + `bs/${bsid}/cases/${caseid}`,
            // `/bs/${bsid}/cases/${this.helper.replaceSlahsForDolar(caseid)}${queryParam}`,
        );
    }

    public getCaseReservationAndStatus(bsid: number, caseid: string): Observable<ReservationStatus[]> {
        return this.httpClient.get(this.helperUrl.getAssistanceUrl(2) + `bs/${bsid}/cases/${caseid}/statuses/preventivni`);
    }
    public cancelBusinessSubjectCase(
        bsId: number,
        caseId: string,
        contractor: Contractor | null = null,
        caseStatus?: CaseStatus,
    ): Observable<boolean> {
        if (!contractor || contractor.bpi === 'MEDIFIT' || contractor.bpi === 'SEPARATED') {
            return this.httpClient.delete(this.helperUrl.getAssistanceUrl(2) + `bs/${bsId}/cases/${this.helperUrl.replaceSlahsForDolar(caseId)}/`, {
                responseType: 'text',
            });
        } else {
            return this.httpClient.delete(
                this.helperUrl.getAssistanceUrl() +
                    `bs/${bsId}/cases/${this.helperUrl.replaceSlahsForDolar(caseId)}/contractors/${contractor?.contractorId}/${contractor?.bpi}${
                        caseStatus === CaseStatus.WQ_ITEM_CREATED && contractor?.bpi === 'X21' ? '?ignoreX21=true' : ''
                    }`,
                { responseType: 'text' },
            );
        }
    }
    // comments from HIS
    public getCaseContractorComments(bsid: number, caseid: string): Observable<Comment[]> {
        return this.httpClient
            .get(this.helperUrl.getAssistanceUrl(2) + `bs/${bsid}/cases/${this.helperUrl.replaceSlahsForDolar(caseid)}/contractor/comments`)
            .pipe(
                map(el => {
                    if (_.isNil(el.comments)) {
                        return undefined;
                    }
                    return _.get(el, 'comments', []).map((comment: Comment) => {
                        return {
                            type: 'comment',
                            local: false,
                            value: comment,
                            dateAdded: moment(comment.commentAdded).toDate(),
                        };
                    });
                }),
            );
    }
    // comments from PREVENTIVNI
    public getCaseComments(buid: string | number, caseid: string): Observable<Comment[]> {
        return this.httpClient
            .get(this.helperUrl.getAssistanceUrl(2) + `bs/${buid}/cases/${this.helperUrl.replaceSlahsForDolar(caseid)}/comments`)
            .pipe(
                map(el => {
                    if (el.comments === null) {
                        return undefined;
                    }
                    return _.get(el, 'comments', []).map((comment: Comment) => {
                        return {
                            type: 'comment',
                            local: true,
                            value: comment,
                            dateAdded: moment(comment.commentAdded).toDate(),
                        };
                    });
                }),
            );
    }

    // documents from HIS
    public getCaseContractorDocuments(bsid: number, caseid: string, deserializeObject: boolean = false): Observable<ResDocument[]> {
        return this.httpClient.get(this.helperUrl.getAssistanceUrl(2) + `bs/${bsid}/cases/${caseid}/contractor/documents`).pipe(
            map(el => {
                if (!el?.documents) {
                    return [];
                }
                if (!deserializeObject) return el.documents;
                return _.get(el, 'documents', []).map((document: ResDocument) => {
                    return {
                        type: 'document',
                        local: false,
                        value: document,
                        dateAdded: moment(document.documentAdded).toDate(),
                    };
                });
            }),
        );
    }

    // documents from PREVENTIVNI
    public getCaseDocuments(bsid: number, caseid: string, deserializeObject: boolean = false): Observable<ResDocument[]> {
        return this.httpClient.get(this.helperUrl.getAssistanceUrl(2) + `bs/${bsid}/cases/${caseid}/documents`).pipe(
            map(el => {
                if (!el?.documents) {
                    return [];
                }
                if (!deserializeObject) return el.documents;
                return _.get(el, 'documents', []).map((document: ResDocument) => {
                    return {
                        type: 'document',
                        local: true,
                        value: document,
                        dateAdded: moment(document.documentAdded).toDate(),
                    };
                });
            }),
        );
    }

    public addCaseCommunication(bsId: number, caseId: string, comments: Comment[], documents: string[] = []): Observable<CommunicationResponse> {
        const payload = {
            comments,
            documents: documents.map(documentId => {
                return { maskedDocumentId: documentId };
            }),
        };

        return this.httpClient.post(
            this.helperUrl.getAssistanceUrl(2) + `bs/${bsId}/cases/${this.helperUrl.replaceSlahsForDolar(caseId)}/communication`,
            payload,
            {},
        );
    }
}

// export interface Search {
//     id?: string;
//     customer?: string;
//     service?: string;
//     contractor?: string;
//     'app-user'?: string;
// }

// export enum SearcType {
//     id = 'id',
//     customer = 'customer',
//     service = 'service',
//     contractor = 'contractor',
//     'app-user' = 'app-user',
// }
