import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { BehaviorSubject, EMPTY, forkJoin, iif, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, shareReplay, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CaseStatus } from 'src/app/_common/enums/caseStatus.enum';
import { LoaderService } from 'src/app/_common/services/loader.service';
import { NavigateService } from 'src/app/_common/services/navigate.service';
import { BaseState } from 'src/app/_common/state/base/base.state';
import { WorkersService } from '../../../workers/workers.service';
import { ResDocument } from '../../model/res-document';
import { ReservationDetails } from '../../model/reservation-details';
import { ReservationStatus } from '../../model/reservation-status';
import { ErrorResponse } from './../../../../_common/model/errorResponse.model';
import { HelperService } from './../../../../_common/services/helper.service';
import { MfToasterService } from './../../../../_common/services/mf-toaster.service';
import { ReservationsService } from './../../reservations.service';

@Component({
    selector: 'res-reservation-status',
    templateUrl: './res-reservation-status.component.html',
    styleUrls: ['./res-reservation-status.component.scss'],
})
export class ResReservationStatusComponent implements OnInit, OnDestroy {
    constructor(
        private store: Store,
        private reservationRest: ReservationsService,
        private helper: HelperService,
        private toast: MfToasterService,
        private navigate: NavigateService,
        private loader: LoaderService,
        private workersService: WorkersService,
    ) {}

    public documentId?: string;
    public reportDocumentId: string;
    public reservation: ReservationDetails;
    public status: ReservationStatus;
    private onDestroy$ = new Subject<void>();
    private bsId = this.store.selectSnapshot(BaseState.getBSId);
    public initFetchCompleted = false;

    private medifitCustomerId$ = new BehaviorSubject<number | null>(null);
    public customerLink$ = this.createCustomerLink$();

    @Input() reservationDetails: ReservationDetails;

    ngOnInit() {
        this.getInitData();
    }

    public getInitData() {
        forkJoin([
            this.reservationRest.getCaseContractorDocuments(this.bsId, this.reservationDetails.caseId).pipe(
                tap(data => {
                    if (data.length > 1) {
                        data.sort((a: ResDocument, b: ResDocument) => {
                            let result = 1;
                            if (a.documentAdded > b.documentAdded) {
                                result = -1;
                            }
                            return result;
                        });
                    }
                    this.reportDocumentId = data[0]?.documentId;
                }),
                takeUntil(this.onDestroy$),
            ),
            this.reservationRest.getCaseReservationAndStatus(this.bsId, this.reservationDetails.caseId).pipe(
                tap(status => {
                    if (status.length > 0) {
                        this.status = status[0];
                    }
                }),
                takeUntil(this.onDestroy$),
            ),
            this.reservationRest.getSingleReservation(this.bsId, this.reservationDetails.caseId).pipe(
                tap(data => {
                    if (data.length > 0) {
                        this.reservation = data[0];
                        this.medifitCustomerId$.next(data[0]?.customer?.medifitId);
                    } else {
                        //case does not exist
                        this.navigate.closeSidePanel({ reloadData: true });
                    }
                }),
                takeUntil(this.onDestroy$),
            ),
        ])
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: () => {
                    this.initFetchCompleted = true;
                },
                error: () => {
                    this.initFetchCompleted = true;
                },
            });
    }

    get canCancel(): boolean {
        const isEventInTheFuture = () =>
            this.status?.eventType === CaseStatus.WQ_ITEM_CREATED ? true : moment(this.status?.eventDate).isAfter(moment());
        switch (this.reservation?.contractor?.bpi) {
            case 'X21':
            case 'LIST':
            case 'SEPARATED':
                return !this.reservation?.canceled && isEventInTheFuture();
            default:
                return false;
        }
    }
    public cancelCase(withModal: boolean = true) {
        //withModal -> manual cancel
        //!withModal -> cancel as part of reschedule
        const promise = withModal
            ? this.helper.displayAlert('Termin bo preklican!', 'Ali ste prepričani, da želite preklicati termin?')
            : Promise.resolve({ value: true });
        promise.then(result => {
            if (result.value) {
                this.loader.setLoading(true);
                iif(
                    //additionalActions onCancel
                    () => this.reservation.contractor.bpi === 'X21' && this.status.eventType === CaseStatus.WQ_ITEM_CREATED,
                    this.reservationRest.addCaseCommunication(this.bsId, this.reservationDetails.caseId, [{ comment: 'Prosim za preklic naročila' }]),
                    of(true),
                )
                    .pipe(
                        switchMap(() =>
                            this.reservationRest.cancelBusinessSubjectCase(
                                this.bsId,
                                this.reservation?.caseId,
                                this.reservation?.contractor,
                                this.status?.eventType,
                            ),
                        ),
                    )
                    .subscribe({
                        next: () => {
                            this.toast.success(`Termin uspešno preklican.`);
                            this.loader.setLoading(false);
                            this.navigate.closeSidePanel({ reloadData: true });
                        },
                        error: (err: ErrorResponse) => {
                            this.loader.setLoading(false);
                            if (withModal) this.toast.errorAndSend('Napaka pri preklicu termina.', err);
                        },
                    });
            }
        });
    }

    private createCustomerLink$(): Observable<string | null> {
        return this.medifitCustomerId$.pipe(
            takeUntil(this.onDestroy$),
            filter(medifitId => medifitId != null),
            switchMap(medifitId => this.workersService.getEmployeeExternalId(medifitId as number)),
            map(res => `workers/${res.data[0].id}/WORKER_DATA`),
            catchError(() => EMPTY),
            startWith(null),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
