<div class="d-flex">
    <div class="flex-grow-1">
        <div>
            <ul *ngIf="workerTabs" class="nav nav-pills nav-justified">
                <!-- @TODO: Ignored manually on 2023-10-13 -->
                <!-- eslint-disable @angular-eslint/template/use-track-by-function -->
                <li
                    *ngFor="let tab of workerTabs"
                    (click)="selectTab(tab)"
                    class="nav-item mf-pointer font-weight-bold"
                    [ngClass]="{ active: selectedTab === tab }"
                >
                    <!-- eslint-enable -->
                    <a class="nav-link h-100 font--small">{{ tab | workerTabTypeString | uppercase }}</a>
                </li>
            </ul>
        </div>
        <div [ngSwitch]="selectedTab" class="component_container">
            <worker-data *ngSwitchCase="'WORKER_DATA'" [workerData]="workerData$ | async"></worker-data>
            <worker-reservations *ngSwitchCase="'WORKER_RESERVATIONS'" [workerData]="workerData$ | async"></worker-reservations>
        </div>
    </div>
</div>
