import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { AppModulesType } from '../enums/appModulesType.enum';
import { MenuGroup } from '../model/menu-group';
import { MenuItem } from '../model/menu-item';
import { ActiveModulesService } from './active-modules.service';
import { AnalyticsService } from './analytics.service';

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    private mainMenu: MenuGroup[] = [
        {
            groupTitle: 'Pregledi',
            menu: [
                // {
                //     title: 'Naročanje',
                //     icon: 'las pl-1 la-briefcase-medical',
                //     link: '/booking/single',
                // },
                // {
                //     title: 'Naročanje več oseb',
                //     icon: 'las pl-1 la-user-friends',
                //     link: '/send-documentation',
                //     premium: true,
                //     wip: true,
                // },
                // {
                //     title: 'Koledar naročil',
                //     icon: 'las pl-1 la-calendar',
                //     link: '/send-documentation',
                //     wip: true,
                // },
                {
                    title: 'Seznam naročil',
                    icon: 'las pl-1 la-list',
                    link: '/reservations-list',
                    wip: false,
                },
                {
                    title: 'Mreža izvajalcev',
                    icon: 'las pl-1 la-stethoscope',
                    link: '/contractor-network',
                    wip: false,
                },
            ],
        },

        {
            groupTitle: 'Zaposleni',
            menu: [
                {
                    title: 'Seznam delavcev',
                    icon: 'las pl-1 la-poll-h',
                    link: '/workers',
                },
                {
                    title: 'Delovne skupine',
                    icon: 'las pl-1 la-users',
                    link: '/working-groups',
                    module: AppModulesType.WORK_GROUPS,
                },
                // {
                //     title: 'Besedilne predloge',
                //     icon: 'las pl-1 la-school',
                //     link: '/send-documentation',
                //     wip: true,
                // },
            ],
        },
        {
            groupTitle: 'Analitika',
            menu: [
                {
                    title: 'Statistika',
                    icon: 'las pl-1 la-chart-bar',
                    link: '/statistics',
                    module: AppModulesType.PREVENTIVNI_APP_ANALYTICS,
                },
                //         {
                //             title: 'Napoved stroškov',
                //             icon: 'las pl-1 la-chart-line',
                //             link: '/send-documentation',
                //             premium: true,
                //             wip: true,
                //         },
                //         {
                //             title: 'Pregled plačil',
                //             icon: 'las pl-1 la-money-check',
                //             link: '/send-documentation',
                //             premium: true,
                //             wip: true,
                //         },
            ],
        },

        {
            groupTitle: 'Nastavitve',
            menu: [
                // { title: 'Nastavitve', icon: 'las  mf-i-nut', link: '/settings/user' },
                { title: 'Moje podjetje', icon: 'pl-1  las  la-hospital', link: '/settings/bussines-info' },
                { title: 'Moj račun', icon: 'pl-1  las  la-user', link: '/settings/user-profile', wip: false },
                { title: 'Odjava', icon: 'pl-1  las  la-sign-out-alt', link: '/public/logout' },
            ],
        },
    ];

    public mainMenu$: Observable<MenuGroup[]> = this.analyticsService.metabaseAnalyticsDashboards$.pipe(
        catchError(() => {
            return of(null);
        }),
        map(metabaseAnalyticsDashboards => {
            const mainMenu = _.cloneDeep(this.mainMenu);
            const analyticsGroup = mainMenu.find(item => {
                return item.groupTitle === 'Analitika';
            });
            if (analyticsGroup && metabaseAnalyticsDashboards) {
                analyticsGroup.menu.push(
                    ...metabaseAnalyticsDashboards.map(g => {
                        return {
                            title: g.label,
                            icon: 'las pl-1 la-poll-h',
                            link: `/analytics`,
                            queryParams: {
                                view: g.id,
                            },
                        } as MenuItem;
                    }),
                );
            }
            return this.validateMenu(mainMenu);
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
    );

    constructor(
        private am: ActiveModulesService,
        private analyticsService: AnalyticsService,
    ) {}

    private validateMenu(menuGroup: MenuGroup[]): MenuGroup[] {
        return menuGroup.filter(menuG => {
            menuG.menu = menuG.menu.filter(item => {
                if (item.module != undefined) {
                    return this.am.isAM(item.module);
                }
                return true;
            });
            return menuG?.menu?.length > 0;
        });
    }
}
