import { gql } from 'apollo-angular';
import { Employer } from '../../_common/model/employer';

export type EmployerQueryResponse = {
    employer: Employer;
};

export const GET_EMPLOYER = gql<EmployerQueryResponse, { id: string }>`
    query GetEmployer($id: String!) {
        employer(id: $id) {
            id
            name
            address
            postCode
            city
            taxNumber
            registrationNumber
            economicActivity
            skdCode
            settings {
                referralDefaultLocation
                defaultEmail
            }
        }
    }
`;

export type UpdateEmployerMutationResponse = {
    updateEmployer: Employer;
};

export type EmployerInput = {
    employer: Employer;
};

export const UPDATE_EMPLOYER = gql<UpdateEmployerMutationResponse, EmployerInput & { id: string }>`
    mutation UpdateEmployer($id: String!, $employer: EmployerInput!) {
        updateEmployer(id: $id, employer: $employer) {
            id
            name
            address
            postCode
            city
            taxNumber
            registrationNumber
            economicActivity
            skdCode
            settings {
                referralDefaultLocation
                defaultEmail
            }
        }
    }
`;

export type EmployersQueryResponse = {
    employers: [Employer];
};

export const GET_EMPLOYER_ID_BY_BS_ID = gql<EmployersQueryResponse, { bsId: number }>`
    query GetEmployerIdByBsId($bsId: Long!) {
        employers(bsId: $bsId) {
            id
        }
    }
`;
