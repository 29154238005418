<div class="m-content mf-o-index-table">
    <div class="my-1 ml-2"><h4>Zadnji dogodki</h4></div>
    <small class="text-primary ml-2">Prikazane so spremembe v zadnjih 30 dneh</small>
    <hr />
    <div class="m-portlet">
        <div class="m-portlet__body">
            <ngx-datatable
                #mainTable
                [rows]="allNotifications"
                [rowClass]="getRowClass"
                [columnMode]="'flex'"
                [rowHeight]="'auto'"
                [footerHeight]="50"
                [limit]="limit"
                [selectionType]="'single'"
                [messages]="{ emptyMessage: 'Ni podatkov za prikaz.', totalMessage: 'skupaj' }"
                (select)="notificationSelected($event)"
                class="bootstrap"
            >
                <!-- <ngx-datatable-column name="" [flexGrow]="1" [maxWidth]="50" [sortable]="false">
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                    <com-reservation-status
                    [type]="'badge'"
                    [setData]="{
                        caseStatus: row.data.type,
                        canceled: false,
                        isDocumentUploaded: true
                    }"
                ></com-reservation-status>
                </ng-template>
            </ngx-datatable-column> -->
                <ngx-datatable-column [flexGrow]="1" [sortable]="false" name="ID NAROČILA" prop="data">
                    <ng-template ngx-datatable-cell-template let-value="value">
                        <span *ngIf="value?.caseId?.length > 19; else normalCaseId" container="body" ngbTooltip="{{ value?.caseId }}">
                            {{ value?.caseId | slice: 0 : 16 }} ...
                        </span>
                        <ng-template #normalCaseId>
                            {{ value?.caseId || '/' }}
                        </ng-template>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [flexGrow]="1" [sortable]="false" name="DELAVEC" prop="customerName">
                    <ng-template ngx-datatable-cell-template let-value="value">
                        <span *ngIf="value?.length > 19; else normalCustomerText" container="body" ngbTooltip="{{ value }}">
                            {{ value | slice: 0 : 16 }} ...
                        </span>
                        <ng-template #normalCustomerText>
                            {{ value || '/' }}
                        </ng-template>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [flexGrow]="2" [sortable]="false" name="IZVAJALEC" prop="data">
                    <ng-template ngx-datatable-cell-template let-value="value">
                        <span *ngIf="value?.contractorName?.length > 30; else normalText" container="body" ngbTooltip="{{ value?.contractorName }}">
                            {{ value?.contractorName | slice: 0 : 26 }} ...
                        </span>
                        <ng-template #normalText>
                            {{ value?.contractorName || '/' }}
                        </ng-template>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [flexGrow]="2" [sortable]="false" name="DOGODEK" prop="data">
                    <ng-template ngx-datatable-cell-template let-value="value">
                        {{ value?.msg || '/' }}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [flexGrow]="1" [sortable]="false" name="DATUM SPREMEMBE" prop="notificationDate">
                    <ng-template ngx-datatable-cell-template let-value="value">
                        {{ value | date: 'dd.MM.yyyy | HH:mm' }}
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
        </div>
    </div>
</div>
