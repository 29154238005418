<div *ngIf="headerInfoData" class="d-flex flex-wrap align-items-center justify-content-between p-3 mf-header__header">
    <ng-container *ngIf="isConnectionOk; else connectionWarning">
        <span class="d-flex align-items-center ml-2">
            <i class="la-2x mr-2" [ngClass]="headerInfoData?.iconClass"></i>
            <strong>{{ headerInfoData?.title || '/' }}</strong>
        </span>
        <span class="mr-3">
            {{ headerInfoData?.description || '/' }}
        </span>
    </ng-container>
    <ng-template #connectionWarning>
        <span class="d-flex align-items-center ml-2 text-danger">
            <i class="la-2x mr-2 las la-exclamation"></i>
            <strong>Izgubili ste internetno povezavo</strong>
        </span>
    </ng-template>
</div>
