import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable } from 'rxjs';
import { MedaViewComponent } from './meda-view.component';
import { OpenDocumentRequest } from './model/open-document-request';

@Injectable({
    providedIn: 'root',
})
export class MedaViewService {
    constructor(private modalService: NgbModal) {}

    public openDocumentViewer(data: OpenDocumentRequest, filename: string, print?: boolean, viewOnly = false): Observable<string> {
        const modalMedidaView = this.modalService.open(MedaViewComponent, {
            size: 'xl',
            windowClass: 'mf-transparent-popup',
        });
        modalMedidaView.componentInstance.print = print;
        modalMedidaView.componentInstance.setdocumentFile = data.document;
        modalMedidaView.componentInstance.fileName = filename;
        modalMedidaView.componentInstance.documentType = data.document.type;
        modalMedidaView.componentInstance.viewOnly = viewOnly;
        // modalMedidaView.componentInstance.signature = signature;
        // modalMedidaView.componentInstance.navigationButtons = navigationButtons;
        return from(modalMedidaView.result);

        // if (type === 'application/pdf' || type.includes('image') || type == 'text/plain' || type.includes('video')) {
        //     const modalMedidaView = this.modalService.open(MedaViewComponent, {
        //         size: 'lg',
        //         windowClass: 'mf-transparent-popup',
        //     });
        //     modalMedidaView.componentInstance.print = print;
        //     modalMedidaView.componentInstance.setdocumentFile = data;
        //     modalMedidaView.componentInstance.fileName = filename;
        //     modalMedidaView.componentInstance.documentType = type;
        //     // modalMedidaView.componentInstance.signature = signature;
        //     modalMedidaView.componentInstance.navigationButtons = navigationButtons;
        //     // if (print) {
        //     //     modalMedidaView.componentInstance.printFile();
        //     // }

        //     return from(modalMedidaView.result);
        // } else {
        //     //unknown type of document
        //     const modalMedidaView = this.modalService.open(MediaViewPopupComponent, {
        //         size: 'lg',
        //         windowClass: 'mf-transparent-popup',
        //     });
        //     modalMedidaView.componentInstance.setdocumentFile = data;
        //     modalMedidaView.componentInstance.fileName = filename;
        //     modalMedidaView.componentInstance.documentType = type;
        //     modalMedidaView.componentInstance.navigationButtons = navigationButtons;
        //     return from(modalMedidaView.result);
        // }
    }
}
