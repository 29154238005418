import { NgModule } from '@angular/core';
import { ComponentsModule } from './../../_common/component/components.module';
// @TODO: Ignored with eslint-interactive on 2023-09-15
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TextMaskModule } from 'angular2-text-mask';
import { PipesModule } from 'src/app/_common/pipes/pipes.module';
import { WorkerDataComponent } from './components/worker-details/worker-data/worker-data.component';
import { WorkerDetailsComponent } from './components/worker-details/worker-details.component';
import { WorkerReservationsComponent } from './components/worker-details/worker-reservations/worker-reservations.component';
import { WorkerTabTypeStringPipe } from './pipes/worker-tab-type-string.pipe';
import { WorkTemplateComponent } from './work-template/work-template.component';
import { WorkersComponent } from './workers.component';

const routes: Routes = [
    {
        path: '',
        component: WorkTemplateComponent,
        children: [
            {
                path: '',
                component: WorkersComponent,
            },
            {
                path: ':id',
                component: WorkersComponent,
            },
            {
                path: ':id/:selectedTab',
                component: WorkersComponent,
            },
        ],
    },
];
@NgModule({
    declarations: [
        WorkersComponent,
        WorkTemplateComponent,
        WorkerDetailsComponent,
        WorkerDataComponent,
        WorkerReservationsComponent,
        WorkerTabTypeStringPipe,
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        ComponentsModule,
        NgxDatatableModule,
        ReactiveFormsModule,
        TextMaskModule,
        NgbModule,
        NgSelectModule,
        PipesModule,
    ],
    exports: [WorkerDetailsComponent],
    providers: [WorkerDetailsComponent],
})
export class WorkersModule {}
