import { Component, Input, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import * as moment from 'moment';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ReferralType } from '../../../../_common/enums/referral-type.enum';
import {
    WorkGroupTemplate,
    Workplace,
    WorkplaceDefinition,
    WorkplaceDefinitionResponse,
    WorkplaceTemplate,
} from '../../../../_common/model/work-groups-template';
import { WorkingGroupsService } from '../../working-groups.service';
import { HelperService } from './../../../../_common/services/helper.service';
import { MfToasterService } from './../../../../_common/services/mf-toaster.service';
import { NavigateService } from './../../../../_common/services/navigate.service';
import { BaseState } from './../../../../_common/state/base/base.state';

@Component({
    selector: 'app-work-group-details',
    templateUrl: './work-group-details.component.html',
    styleUrls: ['./work-group-details.component.scss'],
})
export class WorkGroupDetailsComponent implements OnDestroy {
    constructor(
        private fb: UntypedFormBuilder,
        private toastr: MfToasterService,
        private helper: HelperService,
        private workGroupsRest: WorkingGroupsService,
        private navigate: NavigateService,
        private store: Store,
    ) {}

    public workGroupForm: UntypedFormGroup;
    private onDestroy$ = new Subject<void>();
    public workplaceDefinition: WorkplaceDefinition;

    public isSubmitted = false;
    public today = moment().toDate();
    public referralTypes: ReferralType[] = [ReferralType.F8204, ReferralType.F8205];
    public bsId = this.store.selectSnapshot(BaseState.getBSId);
    public employerId = this.store.selectSnapshot(BaseState.getEmployerId);

    @Input() set setData(value: WorkplaceDefinition) {
        // init form
        if (!this.workGroupForm) {
            this.initForm();
        }
        this.workGroupForm?.markAsPristine();
        this.workplaceDefinition = value;
        // patch form
        this.workGroupForm.reset();
        this.workGroupForm.patchValue({
            name: this.workplaceDefinition?.name,
        });
        // auto select f8204
        this.selectReferralType(ReferralType.F8204);
    }

    get formControls() {
        return (this.workGroupForm.get('template') as UntypedFormGroup)?.controls;
    }

    get selectedReferral(): ReferralType {
        return this.workGroupForm?.value?.template?.templateType;
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    private initForm(): void {
        this.workGroupForm = this.fb.group({
            name: this.fb.control({ value: undefined, disabled: false }, Validators.required),
            template: this.fb.group({
                templateType: this.fb.control({ value: undefined, disabled: false }),
                riskAssessmentDate: this.fb.control({ value: undefined, disabled: false }),
                riskAssessment: this.fb.control({ value: undefined, disabled: false }),
                workEquipment: this.fb.control({ value: undefined, disabled: false }),
                workplaceAssets: this.fb.control({ value: undefined, disabled: false }),
                riskExposure: this.fb.control({ value: undefined, disabled: false }),
                measurementsAfterRiskAssessment: this.fb.control({ value: undefined, disabled: false }),
                personalProtectiveGear: { value: undefined, disabled: false },
                specialHealthRequirements: this.fb.control({ value: undefined, disabled: false }),
                workplaceInappropriateFor: this.fb.control({ value: undefined, disabled: false }),
                employerComments: this.fb.control({ value: undefined, disabled: false }),
            }),
        });
    }

    public submitWorkingGroupData(applyToAllTypes = false): void {
        const selected = _.cloneDeep(this.selectedReferral);
        of(null)
            .pipe(
                tap(() => (this.isSubmitted = true)),
                switchMap(() => {
                    if (applyToAllTypes) {
                        return this.helper.displayAlert(
                            'Shrani za vse!',
                            'Ali ste prepričani, da želite podatke delovne skupine uporabiti za vse tipe napotnic?',
                        );
                    }
                    return of({ isConfirmed: true });
                }),
                switchMap(result => {
                    if (this.workGroupForm.valid && result?.isConfirmed) {
                        return !_.isNil(this.workplaceDefinition?.id)
                            ? this.updateWorkplaceDefinition(applyToAllTypes)
                            : this.createWorkplaceDefinition(applyToAllTypes);
                    }
                    return EMPTY;
                }),
                tap(workplaceDefinitionResponse => {
                    this.workGroupForm.markAsPristine();
                    this.isSubmitted = false;
                    if (workplaceDefinitionResponse) {
                        this.workplaceDefinition = workplaceDefinitionResponse.data;
                        this.selectReferralType(selected);

                        this.toastr.success('Spremembe uspešno shranjene.');
                    } else {
                        this.toastr.error('Napaka pri shranjevanju sprememb.');
                    }
                }),
                catchError(err => {
                    this.toastr.errorAndSend('Napaka pri shranjevanju sprememb.', err);
                    return EMPTY;
                }),
            )
            .subscribe();
    }

    private prepareWorkGroupData(): [{ name: string; template: WorkGroupTemplate }, WorkplaceTemplate, ReferralType | undefined] {
        const data = _.cloneDeep(this.workGroupForm.value);
        const referralType = this.getReferralTypeEnum(data?.template?.templateType);
        return [data, data.template, referralType];
    }

    private createWorkplaceDefinition(applyToAllTypes = false): Observable<WorkplaceDefinitionResponse | undefined> {
        const [data, template, referralType] = this.prepareWorkGroupData();

        if (!referralType) {
            return of();
        }

        // Single or all
        const referralTypesToCreate = [referralType];
        if (applyToAllTypes) {
            this.referralTypes.forEach(rType => {
                if (!referralTypesToCreate.includes(rType)) {
                    referralTypesToCreate.push(rType);
                }
            });
        }

        // Create single or all
        const workpaces = [] as Workplace[];
        referralTypesToCreate.forEach(rType => {
            const workplaceTemplateName = `${data.name}-${rType}`;
            delete template.templateType;

            const templateClone = _.cloneDeep(template);
            if (template?.riskAssessmentDate) {
                templateClone.riskAssessmentDate = moment(template.riskAssessmentDate, ['DD.MM.YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD');
            }

            workpaces.push({
                employerId: this.employerId,
                referralType: rType,
                workplaceTemplate: { ...templateClone, name: workplaceTemplateName, employerId: this.employerId },
            });
        });

        const payload: WorkplaceDefinition = {
            name: data.name,
            employerId: this.employerId,
            workplaces: workpaces,
        };

        return this.workGroupsRest.createWorkplace(payload);
    }

    private updateWorkplaceDefinition(applyToAllTypes = false): Observable<WorkplaceDefinitionResponse | undefined> {
        const [data, template, referralType] = this.prepareWorkGroupData();

        if (!referralType || !this.workplaceDefinition?.id) {
            return of();
        }

        // Single or all
        const referralTypesToCreate = [referralType];
        if (applyToAllTypes) {
            this.referralTypes.forEach(rType => {
                if (!referralTypesToCreate.includes(rType)) {
                    referralTypesToCreate.push(rType);
                }
            });
        }

        // Update single or all
        const workpaces = [] as Workplace[];
        referralTypesToCreate.forEach(rType => {
            const workplaceTemplateName = `${data.name}-${rType}`;
            delete data.template.templateType;

            const existingWorkplace = this.workplaceDefinition?.workplaces?.find(workplace => workplace.referralType === rType);

            const templateClone = _.cloneDeep(template);
            if (existingWorkplace && existingWorkplace.workplaceTemplate?.id) {
                templateClone.id = existingWorkplace.workplaceTemplate?.id;
            }
            if (template?.riskAssessmentDate) {
                templateClone.riskAssessmentDate = moment(template.riskAssessmentDate, ['DD.MM.YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD');
            }

            workpaces.push({
                employerId: this.employerId,
                referralType: rType,
                workplaceTemplate: { ...templateClone, name: workplaceTemplateName, employerId: this.employerId },
            });
        });

        const payload: WorkplaceDefinition = {
            id: this.workplaceDefinition.id,
            name: data.name,
            employerId: this.employerId,
            workplaces: workpaces,
        };

        return this.workGroupsRest.updateWorkplaceDefinition(payload);
    }

    private getReferralTypeEnum(enumString: string): ReferralType | undefined {
        return Object.values(ReferralType).find((refType: ReferralType) => {
            if (refType === enumString) {
                return refType;
            }
        });
    }

    public selectReferralType(refType: ReferralType): void {
        if (refType !== this.selectedReferral) {
            // data not saved -> modal warning
            const promise = !this.workGroupForm?.controls.template?.pristine
                ? this.helper.displayAlert('Podatki niso shranjeni!', 'Ali ste prepričani, da želite spremeniti tip naročilnice?')
                : Promise.resolve({ value: true });
            promise.then((result: { value: boolean }) => {
                if (result.value) {
                    const referralTypeData = this.workplaceDefinition?.workplaces?.find(w => w.referralType === refType);
                    this.workGroupForm?.controls.template.reset({
                        ...referralTypeData?.workplaceTemplate,
                        templateType: refType,
                        active: true,
                    });
                }
            });
        }
    }

    public deleteWorkplaceDefinition(): void {
        this.helper.displayAlert('Brisanje skupine!', 'Ali ste prepričani, da želite izbrisati delovno skupino?').then(result => {
            if (result.value && this.workplaceDefinition?.id) {
                this.workGroupsRest
                    .deleteWorkplaceDefinition(this.workplaceDefinition.id)
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe({
                        next: () => {
                            this.toastr.success('Skupina uspešno izbrisana.');
                            this.navigate.closeSidePanel();
                        },
                        error: err => {
                            this.toastr.errorAndSend('Napaka pri brisanju skupine.', err);
                        },
                    });
            }
        });
    }
}
