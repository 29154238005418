import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuGroup } from '../../../../_common/model/menu-group';
import { MenuService } from '../../../../_common/services/menu.service';

@Component({
    selector: 'lay-men-menu',
    templateUrl: './lay-men-menu.component.html',
    styleUrls: ['./lay-men-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LayMenMenuComponent implements OnInit {
    @Input()
    public isMin = false;

    public menuGroup: Observable<MenuGroup[]>;
    constructor(private menu: MenuService) {}

    ngOnInit() {
        this.menuGroup = this.menu.mainMenu$;
    }
}
