import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-com-loading',
    templateUrl: './com-loading.component.html',
    styleUrls: ['./com-loading.component.scss'],
})
export class ComLoadingComponent implements OnInit {
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit() {}
}
