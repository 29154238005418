<!-- @TODO: Ignored manually on 2023-10-13 -->
<!-- eslint-disable @angular-eslint/template/use-track-by-function -->
<div *ngFor="let group of menuGroup | async" class="mf-menu-group pb-2">
    <!-- eslint-enable -->
    <div class="text-uppercase mf-menu-group_title">
        <span class="mf-menu-group_title_text pl-2 font-weight-bold">
            {{ group.groupTitle }}
        </span>
    </div>
    <!-- @TODO: Ignored manually on 2023-10-13 -->
    <!-- eslint-disable @angular-eslint/template/use-track-by-function -->
    <div *ngFor="let menu of group.menu" class="mf-menu">
        <!-- eslint-enable -->
        <a
            [routerLink]="menu.link"
            [queryParams]="menu.queryParams"
            [ngbTooltip]="menu.title"
            [disableTooltip]="!isMin"
            [routerLinkActiveOptions]="{ exact: false }"
            tooltipClass="mf-tooltip"
            placement="right"
            container="body"
            routerLinkActive="mf-menu_item--active"
            class="mf-menu_item"
        >
            <i *ngIf="isMin" class="pr-1" [ngClass]="menu.icon"></i>
            <span class="mf-menu_item_text">{{ menu.title }}</span>
            <ng-container *ngIf="!isMin">
                <i *ngIf="menu.wip" ngbTooltip="V razvoju" class="pl-1 las la-hourglass-half text-info"></i>
                <i *ngIf="menu.premium" ngbTooltip="Zaklenjeno" class="pl-1 las la-gem text-brand"></i>
            </ng-container>
        </a>
    </div>
</div>
