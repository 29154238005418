import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { TokenData } from '../../../_modules/public/_model/token-data';
import { BsGuiSettings } from '../../model/bs-gui-settings';
import { BusinessSubject } from '../../model/business-subject';
import { Contractor } from '../../model/contractor.model';
import { Module } from '../../model/module.model';
import { User } from '../../model/user';
import { WorkGroupData } from '../../model/work-group-data';
import { BaseStateModel } from './base-state.model';
import { BaseStateActions } from './base.actions';

const BASESTATE_TOKEN: StateToken<BaseStateModel> = new StateToken('basestate');

const DEFAULT_STATE: BaseStateModel = {
    token: undefined,
    bsGuiSettings: undefined,
    user: undefined,
    contractors: [],
    selectedContractor: undefined,
    contractorGuiSettings: undefined,
    appModules: [],
    bookings: [],
    bsAdditionalInfo: undefined,
    workGroupData: undefined,
    bsSendingRules: undefined,
    activeBS: undefined,
    employerId: undefined,
};

@State<BaseStateModel>({
    name: BASESTATE_TOKEN,
    defaults: DEFAULT_STATE,
    children: [], //ce bo kdaj prov prislo
})
@Injectable()
export class BaseState {
    @Selector([BASESTATE_TOKEN])
    public static getStateData(state: BaseStateModel): BaseStateModel {
        return state;
    }

    @Selector([BASESTATE_TOKEN])
    public static getAccessToken(state: BaseStateModel): string | undefined {
        return state.token?.accessToken;
    }

    @Selector([BASESTATE_TOKEN])
    public static getRefreshToken(state: BaseStateModel): string | undefined {
        return state.token?.refreshToken;
    }

    @Selector([BASESTATE_TOKEN])
    public static getToken(state: BaseStateModel): TokenData | undefined {
        return state.token;
    }

    @Selector([BASESTATE_TOKEN])
    public static getBS(state: BaseStateModel): BusinessSubject {
        return state?.activeBS || { id: -1 };
    }

    @Selector([BASESTATE_TOKEN])
    public static getBSId(state: BaseStateModel): number {
        return state?.activeBS?.id || -1;
    }

    @Selector([BASESTATE_TOKEN])
    public static getEmployerId(state: BaseStateModel): string {
        return state?.employerId || '';
    }

    @Selector([BASESTATE_TOKEN])
    public static getUser(state: BaseStateModel): User | undefined {
        return state?.user;
    }

    @Selector([BASESTATE_TOKEN])
    public static getBsGuiSettings(state: BaseStateModel): BsGuiSettings | undefined {
        return state?.bsGuiSettings;
    }

    @Selector([BASESTATE_TOKEN])
    public static getContractors(state: BaseStateModel): Contractor[] | undefined {
        return state.contractors;
    }

    @Selector([BASESTATE_TOKEN])
    public static getSelectedContractor(state: BaseStateModel): Contractor {
        return <Contractor>state.selectedContractor;
    }

    @Selector([BASESTATE_TOKEN])
    public static getSelectedContractorId(state: BaseStateModel): number {
        return <number>state.selectedContractor?.id;
    }

    @Selector([BASESTATE_TOKEN])
    public static GetActiveModules(state: BaseStateModel): Module[] {
        return state.appModules;
    }

    @Selector([BASESTATE_TOKEN])
    public static GetBookingFn(state: BaseStateModel) {
        return (id: string) => state?.bookings.find(book => book.guiId == id);
        // return state.appModules;
    }

    @Selector([BASESTATE_TOKEN])
    public static GetBookings(state: BaseStateModel) {
        return state?.bookings;
    }

    @Selector([BASESTATE_TOKEN])
    public static GetBsAdditionalInfo(state: BaseStateModel) {
        return state?.bsAdditionalInfo;
    }

    @Selector([BASESTATE_TOKEN])
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static GetWorkGroupsData(state: BaseStateModel): WorkGroupData | any {
        return state?.workGroupData;
    }

    @Selector([BASESTATE_TOKEN])
    public static GetBsSendingRules(state: BaseStateModel) {
        return state?.bsSendingRules;
    }

    @Selector([BASESTATE_TOKEN])
    public static getAllBsRoles(state: BaseStateModel) {
        return state?.token?.sdata?.bsRoles;
    }

    @Action(BaseStateActions.Common.SetActiveBS)
    public SetActiveBS(ctx: StateContext<BaseStateModel>, { activeBs }: BaseStateActions.Common.SetActiveBS) {
        ctx.patchState({
            activeBS: activeBs,
        });
    }

    @Action(BaseStateActions.Common.SetEmployerId)
    public SetEmployerId(ctx: StateContext<BaseStateModel>, { employerId }: BaseStateActions.Common.SetEmployerId) {
        ctx.patchState({
            employerId,
        });
    }

    @Action(BaseStateActions.Common.SetTokenData)
    public SetActiveContractor(ctx: StateContext<BaseStateModel>, { tokenData }: BaseStateActions.Common.SetTokenData) {
        ctx.patchState({
            token: tokenData,
        });
    }

    @Action(BaseStateActions.Common.ClearTokenData)
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line no-empty-pattern
    public ClearTokenData(ctx: StateContext<BaseStateModel>, {}: BaseStateActions.Common.ClearTokenData) {
        ctx.patchState({
            token: undefined,
        });
    }

    @Action(BaseStateActions.Common.SetBsGuiSettings)
    public SetBsGuiSettings(ctx: StateContext<BaseStateModel>, { settings }: BaseStateActions.Common.SetBsGuiSettings) {
        // debugger;
        if (settings) {
            ctx.patchState({
                bsGuiSettings: settings,
                appModules: settings.settings.active.map(el => {
                    return {
                        name: el,
                    };
                }),
            });
        }
    }

    @Action(BaseStateActions.Common.SetUser)
    public SetUser(ctx: StateContext<BaseStateModel>, { user }: BaseStateActions.Common.SetUser) {
        ctx.patchState({
            user: user,
        });
    }

    @Action(BaseStateActions.Common.ClearBaseState)
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line no-empty-pattern
    public ClearState(ctx: StateContext<BaseStateModel>, {}: BaseStateActions.Common.ClearBaseState) {
        ctx.patchState(DEFAULT_STATE);
    }

    @Action(BaseStateActions.Common.SetContractors)
    public SetContractors(ctx: StateContext<BaseStateModel>, { contractors }: BaseStateActions.Common.SetContractors) {
        ctx.patchState({
            contractors: contractors,
        });
    }

    @Action(BaseStateActions.Common.SetBsAdditionalInfo)
    public SetBsAdditionalInfo(ctx: StateContext<BaseStateModel>, { additionalInfo }: BaseStateActions.Common.SetBsAdditionalInfo) {
        ctx.patchState({
            bsAdditionalInfo: additionalInfo,
        });
    }

    @Action(BaseStateActions.Common.SetBSSendingRules)
    public SetBSSendingRules(ctx: StateContext<BaseStateModel>, { bsSendingRules }: BaseStateActions.Common.SetBSSendingRules) {
        ctx.patchState({
            bsSendingRules: bsSendingRules,
        });
    }

    @Action(BaseStateActions.Bs.SetBsModules)
    public SetContractorsModules(ctx: StateContext<BaseStateModel>, { modules }: BaseStateActions.Bs.SetBsModules) {
        ctx.patchState({
            appModules: modules,
        });
    }
}
