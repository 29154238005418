<div class="h-100 mf-main">
    <div class="mf-grid" [ngClass]="{ 'mf-min-menu ': min, 'mf-no-header': submenu.length < 1 }">
        <lay-menu (onMenuSizeChange)="min = $event" class="mf-menu"></lay-menu>

        <lay-sub-menu [sumbMenu]="submenu" class="mf-header"></lay-sub-menu>
        <div class="mf-content overflow-hidden d-flex flex-column">
            <lay-header></lay-header>
            <div class="p-3 flex-grow-1 h-100 overflow-auto">
                <router-outlet (activate)="activate($event)"></router-outlet>
            </div>
        </div>
    </div>

    <div class="mf-side-panel shadow--dark" [ngClass]="{ 'mf-side-panel--open': sidePanel?.open }">
        <div class="p-4">
            <div *ngIf="sidePanel?.title" class="border-bottom mb-3">
                <h4 class="text-white flex-grow-1">{{ sidePanel?.title }}</h4>
            </div>
            <div class="pb-2 d-flex align-items-center justify-content-between">
                <div
                    *ngIf="actionButtonsData?.buttons.length"
                    class="d-flex w-100"
                    [ngClass]="{ ' justify-content-between ': actionButtonsData?.spaceBetween }"
                >
                    <!-- @TODO: Ignored manually on 2023-10-13 -->
                    <!-- eslint-disable @angular-eslint/template/use-track-by-function -->
                    <button
                        *ngFor="let button of actionButtonsData?.buttons"
                        [attr.data-testid]="button.dataTestid"
                        (click)="button.onClick(dynamicComponent?.instance)"
                        class="mr-2"
                        [ngClass]="button.buttonClass"
                    >
                        <!-- eslint-enable -->
                        <i class="las" [ngClass]="button.iconClass"></i>
                        {{ button.text }}
                    </button>
                </div>
                <button [attr.data-testid]="'btn-close-panel'" (click)="closeSidePanel()" class="btn btn-outline-white">ZAPRI</button>
            </div>
            <div class="mf-scrollable-content">
                <perfect-scrollbar [config]="config">
                    <div #entry></div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showLoader" class="d-flex justify-content-center loader">
    <div role="status" class="spinner-border text-primary mx-auto">
        <span class="sr-only">Nalagam...</span>
    </div>
</div>
