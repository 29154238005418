import { Injectable } from '@angular/core';
import { BugsnagService } from '@meddev/fe-shared';
import { Store } from '@ngxs/store';
import { BaseState } from '../state/base/base.state';
import { BaseStateModel } from './../state/base/base-state.model';

@Injectable({
    providedIn: 'root',
})
export class ErrorNotifyService {
    constructor(
        private store: Store,
        private bugsnagService: BugsnagService,
    ) {}

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public sendErrorManualy(error: any) {
        const stateData: BaseStateModel = { ...this.store.selectSnapshot(BaseState.getStateData) };
        delete stateData?.contractors;
        const { id } = stateData?.user || {};
        delete stateData.user;
        this.bugsnagService.bugsnagNotify(new Error(JSON.stringify(error || 'Invalid error')), {
            data_in_state: { ...stateData, user: { id } },
        });
    }
}
