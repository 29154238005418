import {
    HttpErrorResponse,
    HttpHandler,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse,
    HttpSentEvent,
    HttpUserEvent,
    HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { ApiUrlService } from '../services/api-url.service';
import { AuthService } from './../../_modules/public/_services/auth.service';
import { HelperService } from './../services/helper.service';
import { HttpClientService } from './../services/http-client.service';
import { MfToasterService } from './../services/mf-toaster.service';
import { BaseStateActions } from './../state/base/base.actions';
import { BaseState } from './../state/base/base.state';
// import { EmployerState } from '../state/employer/employer.state';

@Injectable({
    providedIn: 'root',
})
export class TokenInterceptorService {
    constructor(
        private injector: Injector,
        private tokenExtractor: HttpXsrfTokenExtractor,
        private helper: HelperService,
        private helperUrl: ApiUrlService,
        private toast: MfToasterService,
    ) {}

    private isRefreshingToken = false;
    tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    intercept(
        // @TODO: Ignored with eslint-interactive on 2023-09-15
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        request: HttpRequest<any>,
        next: HttpHandler,
        // @TODO: Ignored with eslint-interactive on 2023-09-15
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {
        if (this.isOneOfIgnoreredUrl(request)) {
            return next.handle(request);
        }
        const storage = this.injector.get(Store);
        const router = this.injector.get(Router);
        const accessToken: string = <string>storage.selectSnapshot(BaseState.getAccessToken);
        return next.handle(this.addTokenToRequest(request, accessToken, '')).pipe(
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    if (request.url === this.helperUrl.getAuthUrl()) {
                        return throwError(err);
                    }
                    switch ((<HttpErrorResponse>err).status) {
                        case 0:
                            console.error('Napaka 0 - restart servisov?');
                            return throwError(err);
                        case 401:
                            if (_.get(err, 'error.error_description') === '2FA') {
                                return throwError(err);
                            }
                            if (_.get(request, 'body', '') && _.get(request, 'body', '').indexOf('refresh_token') !== -1) {
                                storage.dispatch(new BaseStateActions.Common.ClearTokenData());
                                this.toast.info('Seja je potekla.');
                                router.navigate(['/public/login']);
                            }
                            return this.handle401Error(request, next);
                        case 400:
                            return throwError(err);
                        case 403:
                            return throwError(err);
                        default:
                            return throwError(err);
                    }
                } else {
                    return throwError(err);
                }
            }),
        );
    }

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private isOneOfIgnoreredUrl(request: HttpRequest<any>): boolean {
        return /public/.test(request.url) || /hooks.slack.com/.test(request.url) || /token/.test(request.url);
    }

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    private addTokenToRequest(request: HttpRequest<any>, token: string | null, XSRFToken: string): HttpRequest<any> {
        let useToken = true;
        if (request.body && _.isString(request.body)) {
            if (_.get(request, 'body', '').indexOf('refresh_token') !== -1) {
                useToken = false;
            }
        }
        if (!token) {
            useToken = false;
        }

        if (useToken) {
            return request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                },
            });
        } else {
            return request.clone();
        }
    }

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        const httpClientMF = this.injector.get(HttpClientService);
        const authService = this.injector.get(AuthService);
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            return httpClientMF.refreshToken().pipe(
                catchError(() => {
                    this.toast.info('Seja je potekla.');
                    // @TODO: Ignored with eslint-interactive on 2023-09-15
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    return <any>authService.logout();
                }),
                switchMap((token: { access_token: string }) => {
                    const accessToken = _.get(token, 'access_token', undefined);
                    if (accessToken) {
                        this.tokenSubject.next(accessToken);
                        return next.handle(this.addTokenToRequest(request, accessToken, ''));
                    }
                    this.toast.info('Seja je potekla.');
                    // @TODO: Ignored with eslint-interactive on 2023-09-15
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    return <any>authService.logout();
                }),
                catchError(() => {
                    this.toast.info('Seja je potekla.');
                    // @TODO: Ignored with eslint-interactive on 2023-09-15
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    return <any>authService.logout();
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                }),
            );
        } else {
            this.isRefreshingToken = false;

            return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addTokenToRequest(request, token, ''));
                }),
            );
        }
    }
}
