import { Pipe, PipeTransform } from '@angular/core';
import { ReservationDetailsTabType } from './../../_modules/reservations/enum/resDetailsTabsType.enum';

@Pipe({
    name: 'tabTypeString',
})
export class TabTypeStringPipe implements PipeTransform {
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transform(value: ReservationDetailsTabType, args?: string): string {
        if (value) {
            switch (value) {
                case ReservationDetailsTabType.COMMUNICATION:
                    return 'Komunikacija z MDPŠ';
                case ReservationDetailsTabType.REPORT:
                    return 'Spričevalo';
                case ReservationDetailsTabType.REFERRAL:
                    return 'Napotnica';
                case ReservationDetailsTabType.STATUS:
                    return 'Stanje';
                default:
                    return value;
            }
        }
        return '(Ni podatka)';
    }
}
