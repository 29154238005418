import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { AppModulesType } from 'src/app/_common/enums/appModulesType.enum';
import { GenderType } from 'src/app/_common/enums/gender.enum';
import { ErrorResponse } from 'src/app/_common/model/errorResponse.model';
import { WorkGroupData } from 'src/app/_common/model/work-group-data';
import { ActiveModulesService } from 'src/app/_common/services/active-modules.service';
import { HelperService } from 'src/app/_common/services/helper.service';
import { MfToasterService } from 'src/app/_common/services/mf-toaster.service';
import { NavigateService } from 'src/app/_common/services/navigate.service';
import { BaseState } from 'src/app/_common/state/base/base.state';
import { ReservationDetails } from 'src/app/_modules/reservations/model/reservation-details';
import { SettingsService } from 'src/app/_modules/settings/settings.service';
import { WorkingGroupsService } from 'src/app/_modules/working-groups/working-groups.service';
import { SidePanelEvent } from '../../../../../_common/enums/side-panel-event.enum';
import { Employee } from '../../../../../_common/model/employee';
import { SidePanelClose } from '../../../../../_common/model/side-panel-close';
import { ZipCode } from '../../../../../_common/model/zip-code.model';
import { AppDateValidators } from '../../../../../_common/validators/date-validator';
import { WorkersService } from '../../../workers.service';

@Component({
    selector: 'worker-data',
    templateUrl: './worker-data.component.html',
    styleUrls: ['./worker-data.component.scss'],
})
export class WorkerDataComponent implements OnInit, OnDestroy {
    constructor(
        private fb: FormBuilder,
        private store: Store,
        private workerRest: WorkersService,
        private toastr: MfToasterService,
        private router: Router,
        private navigateRest: NavigateService,
        private workGroupsRest: WorkingGroupsService,
        private activeModules: ActiveModulesService,
        private settingsRest: SettingsService,
        private helperService: HelperService,
    ) {}

    public readonly OFFSET_100Y_IN_MONTHS = 100 * 12;
    private employerId = this.store.selectSnapshot(BaseState.getEmployerId);
    public bsAdditionalInfo = this.store.selectSnapshot(BaseState.GetBsAdditionalInfo);
    public workerForm: FormGroup;
    public isSubmitted = false;
    private onDestroy$ = new Subject<void>();
    public isWorkGroupsModuleActive = false;
    public zipCodes: ZipCode[] = [];
    public educationsLevels = this.helperService.getEducationalLevels();
    public genderTypes: typeof GenderType = GenderType;
    public employeeData: Employee;
    public employeeData$ = new BehaviorSubject<Employee | null>(null);

    // date input helpers
    public autoCorrectDatePipe = this.helperService.getAutoCorrectPipe();
    public dateMask: (RegExp | string)[] = this.helperService.getDateMask();
    // typeAhead formatters
    public formatter = (x: { code: string; description: string }) => x.description;
    public workGroupFormatter = (x: WorkGroupData) => x.name;
    public degreeFormatter = (x: { code: string; title: string }) => x.title;
    public skpFormatter = (x: { code: number; descriptionFemale: string; descriptionMale: string; category: string; title: string }) =>
        this.returnGenderDescription(x);

    @Input() set workerData(employee: Employee) {
        this.employeeData = employee;
        this.employeeData$.next(employee);
    }

    ngOnInit(): void {
        this.isWorkGroupsModuleActive = this.activeModules.isAM(AppModulesType.WORK_GROUPS);
        if (!this.workerForm) {
            this.initForm();
        }
        this.workerForm?.markAsPristine();
        this.getZipCodes();

        this.initWorkerDataListener();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    get workerFormData() {
        return this.workerForm?.controls;
    }

    public returnGenderDescription(data: { code: number; descriptionFemale: string; descriptionMale: string; category: string; title: string }) {
        return this.workerFormData?.gender?.value === GenderType.FEMALE ? data.descriptionFemale : data?.descriptionMale;
    }

    private initForm() {
        this.workerForm = this.fb.group({
            firstName: this.fb.control({ value: undefined, disabled: false }, Validators.required),
            lastName: this.fb.control({ value: undefined, disabled: false }, Validators.required),
            address: this.fb.control({ value: undefined, disabled: false }, Validators.required),
            birthDate: this.fb.control({ value: undefined, disabled: false }, [
                Validators.required,
                AppDateValidators.datePatternValidator(AppDateValidators.uiDateFormatRegex),
            ]),
            email: this.fb.control({ value: undefined, disabled: false }, [Validators.email]),
            phoneNumber: this.fb.control({ value: undefined, disabled: false }, [Validators.pattern('^[0-9]*$')]),
            gender: this.fb.control({ value: undefined, disabled: false }, Validators.required),
            emso: this.fb.control({ value: undefined, disabled: false }, [Validators.required, this.validateEMSO]),
            postCode: this.fb.control({ value: undefined, disabled: false }, Validators.required),
            city: this.fb.control({ value: undefined, disabled: false }, Validators.required),
            workGroup: this.fb.control({ value: undefined, disabled: false }),
            birthPlace: this.fb.control({ value: undefined, disabled: false }),
            education: this.fb.control({ value: undefined, disabled: false }),
            educationLevel: this.fb.control({ value: undefined, disabled: true }),
            profession: this.fb.control({ value: undefined, disabled: false }),
            skpCode: this.fb.control({ value: undefined, disabled: false }),
            jobTitle: this.fb.control({ value: undefined, disabled: false }, Validators.required),
            jobCode: this.fb.control({ value: undefined, disabled: false }, [Validators.maxLength(4)]),
            personnelNumber: this.fb.control({ value: undefined, disabled: false }),
            employmentDate: this.fb.control({ value: undefined, disabled: false }, [
                AppDateValidators.datePatternValidator(AppDateValidators.uiDateFormatRegex),
            ]),
            employmentDateForPosition: this.fb.control({ value: undefined, disabled: false }, [
                AppDateValidators.datePatternValidator(AppDateValidators.uiDateFormatRegex),
            ]),
            lastExaminationDate: this.fb.control({ value: undefined, disabled: false }, [
                AppDateValidators.datePatternValidator(AppDateValidators.uiDateFormatRegex),
            ]),
            medifitCustomerId: this.fb.control({ value: undefined, disabled: false }),
            periodicInspectionMonths: this.fb.control(
                {
                    value: undefined,
                    disabled: false,
                },
                [Validators.min(0), Validators.max(60)],
            ),
        });

        (this.workerForm as FormGroup).controls.education?.valueChanges?.subscribe(
            (
                newEducationData:
                    | {
                          code: number;
                          title: string;
                      }
                    | undefined,
            ) => {
                if (newEducationData?.code) {
                    (this.workerForm as FormGroup).controls.education.setValue(newEducationData.title, {
                        emitEvent: false,
                    });
                    (this.workerForm as FormGroup).controls.educationLevel.setValue(newEducationData.code, {
                        emitEvent: false,
                    });
                } else if (!newEducationData) {
                    (this.workerForm as FormGroup).controls.educationLevel.setValue(undefined, {
                        emitEvent: false,
                    });
                }
            },
        );

        (this.workerForm as FormGroup).controls.profession?.valueChanges?.subscribe(
            (
                newProfessionData:
                    | {
                          code: number;
                          title: string;
                          descriptionFemale: string;
                          descriptionMale: string;
                          category: string;
                      }
                    | undefined,
            ) => {
                if (newProfessionData) {
                    (this.workerForm as FormGroup).controls.skpCode.setValidators([Validators.required, Validators.maxLength(6)]);
                    (this.workerForm as FormGroup).controls.skpCode.updateValueAndValidity();
                }
                if (newProfessionData?.code) {
                    (this.workerForm as FormGroup).controls.profession.setValue(this.returnGenderDescription(newProfessionData), {
                        emitEvent: false,
                    });
                    (this.workerForm as FormGroup).controls.skpCode.setValue(newProfessionData.code, {
                        emitEvent: false,
                    });
                } else if (!newProfessionData) {
                    (this.workerForm as FormGroup).controls.skpCode.setValue(undefined, {
                        emitEvent: false,
                    });
                    (this.workerForm as FormGroup).controls.skpCode.setValidators([]);
                    (this.workerForm as FormGroup).controls.skpCode.updateValueAndValidity();
                }
            },
        );
    }

    private patchEmployeeData(employee: Employee) {
        if (employee) {
            this.employeeData = {
                ...employee,
                birthDate: this.formatDate(employee.birthDate) || '',
                employmentDate: this.formatDate(employee.employmentDate),
                employmentDateForPosition: this.formatDate(employee.employmentDateForPosition),
                lastExaminationDate: this.formatDate(employee.lastExaminationDate),
            };

            // patch data
            this.workerForm.patchValue({
                ...this.employeeData,
                workGroup: this.employeeData.workplaceDefinition,
            });
        }
    }

    private formatDate(date?: string): string | undefined {
        if (date && moment(date, 'YYYY-MM-DD').isValid()) {
            return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
        }
    }

    private formatDateBackend(date?: string): string | undefined {
        if (date && moment(date, ['DD.MM.YYYY', 'YYYY-MM-DD']).isValid()) {
            return moment(date, ['DD.MM.YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD');
        }
    }

    public getZipCodes() {
        this.settingsRest
            .getZipCodes()
            .pipe(
                takeUntil(this.onDestroy$),
                map(result => {
                    const sortZipCodes = (a: ZipCode, b: ZipCode): number => {
                        if (a.city < b.city) return -1;
                        if (a.city > b.city) return 1;
                        if (a.zipCode < b.zipCode) return -1;
                        if (a.zipCode > b.zipCode) return 1;
                        return 0;
                    };
                    return result.sort(sortZipCodes);
                }),
            )
            .subscribe((res: ZipCode[]) => {
                this.zipCodes = res;
            });
    }

    public submitWorkerData() {
        const workerData = this.workerForm.getRawValue();
        this.isSubmitted = true;
        if (this.workerForm.valid) {
            if (workerData.workGroup) {
                workerData.workGroupId = workerData.workGroup.id;
            }
            if (!workerData.emso?.length) {
                delete workerData.emso;
            }

            delete workerData.workGroup;

            const updatedEmployeeData: Employee = {
                ...workerData,
                birthDate: this.formatDateBackend(workerData.birthDate) || '',
                employmentDate: this.formatDateBackend(workerData.employmentDate),
                employmentDateForPosition: this.formatDateBackend(workerData.employmentDateForPosition),
                lastExaminationDate: this.formatDateBackend(workerData.lastExaminationDate),
            };

            if (this.employeeData) {
                // UPDATE WORKER
                this.workerRest
                    .updateEmployee(this.employeeData.id, { employerId: this.employerId, ...updatedEmployeeData })
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe(
                        ({ data: employee }) => {
                            this.isSubmitted = false;
                            this.workerForm?.markAsPristine();
                            // patch data
                            this.patchEmployeeData(employee);
                            this.navigateRest.emitSidePanelAction({
                                event: SidePanelEvent.WORKER_UPDATED,
                                eventData: employee,
                            });
                            this.toastr.success('Uspešno urejanje delavca.');
                        },
                        (err: ErrorResponse) => {
                            this.isSubmitted = false;
                            this.toastr.errorAndSend('Napaka pri urejanju delavca.', err);
                        },
                    );
            } else {
                // CREATE WORKER
                this.workerRest
                    .createEmployee({ employerId: this.employerId, ...updatedEmployeeData })
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe(
                        employeeResponse => {
                            this.toastr.success('Uspešno kreiranje delavca.');
                            this.navigateRest.closeSidePanel({
                                reloadData: false,
                                event: SidePanelEvent.WORKER_ADDED,
                                eventData: employeeResponse.data,
                            } as SidePanelClose);
                        },
                        // @TODO: Ignored with eslint-interactive on 2023-09-15
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        err => {
                            this.toastr.error('Napaka pri kreiranju delavca.');
                        },
                    );
            }
        }
    }

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public validateEMSO(control: AbstractControl): { [key: string]: any } | null {
        const emso: string = control?.value;
        if (emso == null || emso?.length === 0) {
            return null;
        }
        if (emso.length < 12) {
            return { emso: false };
        }
        let emsoSum = 0;
        for (let i = 7; i > 1; i--) {
            emsoSum += i * (parseInt(emso.substring(7 - i, 7 - i + 1)) + parseInt(emso.substring(13 - i, 13 - i + 1)));
        }

        let controlDigit;
        if (isNaN(emsoSum)) {
            controlDigit = emsoSum;
        } else {
            const remain = emsoSum % 11;
            if (remain === 1 || remain === 0) {
                controlDigit = 0;
            } else {
                controlDigit = 11 - remain;
            }
        }
        if (emso.substring(12) === controlDigit + '') {
            return null;
        } else {
            return { emso: true };
        }
    }

    public bookTerm() {
        this.router.navigate([`booking/single/${this.employeeData.id}`]);
    }

    public caseSelected({ selected }: { selected: ReservationDetails[] }) {
        this.router.navigate([`reservations-list/reservation/${selected[0].caseId}`]);
    }

    public useCompanyAddress() {
        const employer = this.store.selectSnapshot(BaseState.GetBsAdditionalInfo);
        this.workerForm.controls.address.setValue(employer?.address || '');
        this.workerForm.controls.city.setValue(employer?.city || '');
        this.workerForm.controls.postCode.setValue(employer?.postCode || '');

        this.workerForm.markAsDirty();
    }

    public fillCity(e: ZipCode) {
        if (e.city) {
            (this.workerForm as FormGroup).controls.city.setValue(e.city, {
                emitEvent: false,
            });
        } else {
            // če je mesto iz šifranta, pusti pri miru, če ni pa reset field
            const findCity = this.zipCodes.find(el => el.city == this.workerForm.value.city);
            if (findCity) {
                this.workerForm.controls.city.setValue(e.city);
            }
        }
    }

    public fillPost(e: ZipCode) {
        if (e.zipCode) {
            this.workerForm.controls.postCode.setValue(e.zipCode, {
                emitEvent: false,
            });
        } else {
            // če je pošta iz šifranta, pusti pri miru, če ni pa reset field
            const findPost = this.zipCodes.find(el => el.zipCode === this.workerForm.value.post);
            // če je pošta iz šifranta, pusti pri miru, če ni pa reset field
            if (findPost) {
                this.workerForm.controls.postCode.setValue(e.zipCode, {
                    emitEvent: false,
                });
            }
        }
    }

    // typeAheadSearches
    public searchSkd = (text$: Observable<string>) => {
        return text$
            .pipe(debounceTime(200), distinctUntilChanged())
            .pipe(switchMap((search: string) => this.workerRest.getSkdSearch(search?.toLowerCase() || '')));
    };

    public searchWorkGroups = (text$: Observable<string>) => {
        return text$.pipe(debounceTime(200), distinctUntilChanged()).pipe(
            switchMap((search: string) => {
                return this.workGroupsRest.getWorkplaceDefinitions(this.employerId, {}, search).pipe(map(data => data.data));
            }),
        );
    };

    public searchSkp = (text$: Observable<string>) => {
        return text$
            .pipe(debounceTime(200), distinctUntilChanged())
            .pipe(switchMap((search: string) => this.workerRest.getSkpSearch(search?.toLowerCase() || '')));
    };

    public searchDegree = (text$: Observable<string>) => {
        return text$
            .pipe(distinctUntilChanged())
            .pipe(map((search: string) => this.educationsLevels.filter(edu => edu.title.includes(search) || edu.code.includes(search))));
    };

    public makeEmsoOptional(): void {
        const control = this.workerForm.get('emso');

        if (control) {
            const currentValidators = control.validator
                ? [Validators.required, this.validateEMSO].filter(v => v !== Validators.required)
                : [this.validateEMSO];
            control.setValidators(currentValidators);
            control.updateValueAndValidity();
        }
    }

    private initWorkerDataListener(): void {
        this.employeeData$.pipe(takeUntil(this.onDestroy$)).subscribe(employee => {
            if (employee) {
                this.patchEmployeeData(employee);
            }
        });
    }
}
