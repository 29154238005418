<div class="flex-grow-1 h-100">
    <div class="body d-xl-flex d-sm-inline h-100">
        <div class="col-10 offset-1 py-1 h-100 d-flex flex-column">
            <div *ngIf="documentId" class="d-flex justify-content-end my-2 align-self-center">
                <button
                    *ngIf="documentType === 'REFERRAL'"
                    [disabled]="!referral?.documentId"
                    (click)="downloadDocument(documentType)"
                    class="btn p-2 font--small btn--hover d-flex align-items-end"
                >
                    <!-- NAPOTNICA -->
                    <i class="las la-download mr-1 la-2x"></i>
                    PRENESI NAPOTNICO
                </button>
                <button
                    *ngIf="documentType === 'REPORT'"
                    [disabled]="!report?.documentId"
                    (click)="downloadDocument(documentType)"
                    class="btn btn--hover p-2 font--small d-flex align-items-end"
                >
                    <!-- SPRICEVALO -->
                    <i class="las la-download mr-1 la-2x"></i>
                    PRENESI SPRIČEVALO
                </button>
                <button
                    [disabled]="!report?.documentId || !referral?.documentId"
                    (click)="downloadReferralAndReport()"
                    class="btn ml-3 p-2 btn--hover font--small d-flex align-items-end"
                >
                    <!-- NAPOTNICA + SPRICEVALO -->
                    <i class="las la-download mr-1 la-2x"></i>
                    PRENESI NAPOTNICO IN SPRIČEVALO
                </button>
            </div>
            <div class="h-100">
                <div *ngIf="!initFetchCompleted; else fetchCompletedBlock" class="my-5">
                    <com-inline-loading [onlyIcon]="true"></com-inline-loading>
                </div>
                <ng-template #fetchCompletedBlock>
                    <com-view-referral *ngIf="documentId" [setDocumentId]="documentId" class="h-100"></com-view-referral>
                    <div *ngIf="!documentId" class="my-4">
                        <h5>
                            <span *ngIf="documentType === 'REFERRAL'; else noReport">Napotnica še ni naložena.</span>
                            <ng-template #noReport>Zdravniško spričevalo še ni naloženo.</ng-template>
                        </h5>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
