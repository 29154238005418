import { Pipe, PipeTransform } from '@angular/core';
import { ReferralType } from '../enums/referral-type.enum';

@Pipe({
    name: 'referralString',
})
export class ReferralStringPipe implements PipeTransform {
    transform(value: ReferralType, args?: string): string {
        if (value) {
            switch (value) {
                case ReferralType.F8204:
                    return args === 'short' ? 'Predhodni pregled' : 'Predhodni preventivni zdravstveni pregled';
                case ReferralType.F8205:
                    return args === 'short' ? 'Obdobni pregled' : 'Usmerjeni obdobni zdravstveni pregled';
                case ReferralType.MANAGER:
                    return 'Managerski pregled';
                case ReferralType.GENERIC:
                    return 'Generična napotnica';
                default:
                    return value;
            }
        }
        return '(Ni podatka)';
    }
}
