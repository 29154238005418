import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'com-inline-loading',
    templateUrl: './com-inline-loading.component.html',
    styleUrls: ['./com-inline-loading.component.scss'],
})
export class ComInlineLoadingComponent implements OnInit {
    @Input()
    text = 'Nalagam podatke';

    @Input()
    onlyIcon = false;
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit() {}
}
