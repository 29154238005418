import { Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { Intercom } from 'ng-intercom';
import { NgxPendoService } from 'ngx-pendo';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { debounceTime } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MenuItem } from './../../_common/model/menu-item';
import { SidePanelButtons, SidePanelData } from './../../_common/model/side-panel-data';
import { LoaderService } from './../../_common/services/loader.service';
import { NavigateService } from './../../_common/services/navigate.service';
import { RulesService } from './../../_common/services/rules.service';
import { BaseState } from './../../_common/state/base/base.state';

@Component({
    selector: 'thm-index-menu',
    templateUrl: './thm-index-menu.component.html',
    styleUrls: ['./thm-index-menu.component.scss'],
})
export class ThmIndexMenuComponent implements OnInit {
    public min = false;
    public submenu: MenuItem[] = [];
    public sidePanel: SidePanelData;
    @ViewChild('entry', { read: ViewContainerRef }) entry: ViewContainerRef;

    public config: PerfectScrollbarConfigInterface;
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public dynamicComponent: ComponentRef<any>;
    public userData = this.store.selectSnapshot(BaseState.getUser);
    public showLoader = false;
    public actionButtonsData: SidePanelButtons;
    constructor(
        private permissionsService: RulesService,
        private navigate: NavigateService,
        private resolver: ComponentFactoryResolver,
        private intercom: Intercom,
        private store: Store,
        private loader: LoaderService,
        protected ngxPendoService: NgxPendoService,
    ) {
        loader.isLoading$.pipe(debounceTime(100)).subscribe(showLoader => (this.showLoader = showLoader));
    }

    ngOnInit() {
        this.navigate.currentStatus.pipe(debounceTime(100)).subscribe(data => {
            const dataDelayMs = this.sidePanel?.open && data?.open ? 0 : 260;
            this.sidePanel = data;
            if (this.entry) {
                this.entry.clear();
            }
            if (this.sidePanel?.open && this.sidePanel?.component) {
                this.actionButtonsData = this.sidePanel.buttonsData || { spaceBetween: false, buttons: [] };
                const inquiryComponent = this.resolver.resolveComponentFactory(this.sidePanel.component);
                this.dynamicComponent = this.entry.createComponent(inquiryComponent);
                setTimeout(() => {
                    this.dynamicComponent.instance.setData = this.sidePanel.params.setData;
                }, dataDelayMs);
            }
        });
        const companyName = this.store.selectSnapshot(BaseState.GetBsAdditionalInfo)?.name;
        if (environment.RELEASE_STAGE !== 'test') {
            this.intercom.boot({
                app_id: environment.INTERCOM_KEY,
                name: this.userData?.name, // Full name
                email: this.userData?.email, // Email address
                created_at: moment().unix(), // Signup date as a Unix timestamp
                subcontractorId: this.userData?.subcontractorId,
                phone: this.userData?.phone,
                companyName,
                alignment: 'right',
                horizontal_padding: 30,
                vertical_padding: window.innerHeight * 0.12,
                widget: {
                    activator: '#intercom',
                },
            });
        }
        this.ngxPendoService.initialize({
            id: `${this.userData?.id}`,
            name: this.userData?.name || '',
            email: this.userData?.email || '',
        });
    }

    activate(event: { submenu: MenuItem[] }) {
        this.submenu = this.validateSubMenu(event?.submenu);
    }

    private validateSubMenu(subMenu: MenuItem[]): MenuItem[] {
        const allPermissions = this.permissionsService.getAllRules();
        if (!subMenu) {
            return [];
        }
        return subMenu?.filter(menu => {
            if (!menu.rule) {
                return true;
            }
            if (allPermissions[menu.rule]) {
                return true;
            }
            return false;
        });
    }
    public closeSidePanel(): void {
        this.dynamicComponent.destroy();
        this.sidePanel.open = false;
        this.navigate.closeSidePanel({
            reloadData: this.sidePanel?.params?.reloadOnClose,
        });
    }
}
