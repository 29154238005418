import { gql } from 'apollo-angular';
import { WorkplaceDefinition, WorkplaceDefinitionResponse, WorkplaceDefinitionsResponse } from '../../_common/model/work-groups-template';

export type WorkplaceDefinitionsQuery = {
    employerId: string;
    name?: string;
    order: string;
    orderDirection: string;
    limit: number;
    offset: number;
};

export type WorkplaceDefinitionsQueryResponse = {
    workplaceDefinitions: WorkplaceDefinitionsResponse;
};

export const GET_WORKPLACE_DEFINITIONS = gql<WorkplaceDefinitionsQueryResponse, WorkplaceDefinitionsQuery>`
    query QueryWorkplaces($employerId: String!, $name: String, $order: String, $orderDirection: String, $limit: Int!, $offset: Int!) {
        workplaceDefinitions(employerId: $employerId, name: $name, order: $order, orderDirection: $orderDirection, limit: $limit, offset: $offset) {
            data {
                id
                employerId
                active
                name
                code
                examinationIntervalInMonths
                employeesCount
                workplaces {
                    referralType
                }
                createdAt
                updatedAt
            }
            metadata {
                totalCount
                currentPage
                totalPages
            }
        }
    }
`;

export type WorkplaceDefinitionQueryResponse = {
    workplaceDefinition: WorkplaceDefinition;
};

export const GET_WORKPLACE_DEFINITION = gql<WorkplaceDefinitionQueryResponse, { id: string }>`
    query QueryWorkplaceDefinition($id: String!) {
        workplaceDefinition(id: $id) {
            id
            name
            workplaces {
                referralType
                workplaceTemplate {
                    id
                    riskAssessment
                    riskAssessmentDate
                    workEquipment
                    workplaceAssets
                    riskExposure
                    measurementsAfterRiskAssessment
                    personalProtectiveGear
                    specialHealthRequirements
                    workplaceInappropriateFor
                    employerComments
                }
            }
        }
    }
`;

export type CreateWorkplaceDefinitionResponse = {
    createFullWorkplace: WorkplaceDefinitionResponse;
};

export type WorkplaceDefinitionInput = {
    workplaceDetails: WorkplaceDefinition;
};

export const CREATE_WORKPLACE_DEFINITION = gql<CreateWorkplaceDefinitionResponse, WorkplaceDefinitionInput>`
    mutation CreateWorkplaceDefinition($workplaceDetails: WorkplaceFullInput!) {
        createFullWorkplace(workplaceFullInput: $workplaceDetails) {
            data {
                id
                name
                workplaces {
                    referralType
                    workplaceTemplate {
                        id
                        riskAssessment
                        riskAssessmentDate
                        workEquipment
                        workplaceAssets
                        riskExposure
                        measurementsAfterRiskAssessment
                        personalProtectiveGear
                        specialHealthRequirements
                        workplaceInappropriateFor
                        employerComments
                    }
                }
            }
        }
    }
`;

export type UpdateWorkplaceDefinitionResponse = {
    updateFullWorkplace: WorkplaceDefinitionResponse;
};

export const UPDATE_WORKPLACE_DEFINITION = gql<UpdateWorkplaceDefinitionResponse, WorkplaceDefinitionInput>`
    mutation UpdateWorkplaceDefinition($workplaceDetails: WorkplaceFullInput!) {
        updateFullWorkplace(workplaceFullInput: $workplaceDetails) {
            data {
                id
                name
                workplaces {
                    referralType
                    workplaceTemplate {
                        id
                        riskAssessment
                        riskAssessmentDate
                        workEquipment
                        workplaceAssets
                        riskExposure
                        measurementsAfterRiskAssessment
                        personalProtectiveGear
                        specialHealthRequirements
                        workplaceInappropriateFor
                        employerComments
                    }
                }
            }
        }
    }
`;

export const DELETE_WORKPLACE_DEFINITION = gql<void, { id: string }>`
    mutation DeleteWorkplaceDefinition($id: String!) {
        deleteWorkplaceDefinition(id: $id)
    }
`;
