import { Pipe, PipeTransform } from '@angular/core';
import { WorkerDetailsTabType } from './../enums/workerDetailsTabsType.enum';

@Pipe({
    name: 'workerTabTypeString',
})
export class WorkerTabTypeStringPipe implements PipeTransform {
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transform(value: WorkerDetailsTabType, args?: string): string {
        if (value) {
            switch (value) {
                case WorkerDetailsTabType.WORKER_DATA:
                    return 'Podatki delavca';
                case WorkerDetailsTabType.WORKER_RESERVATIONS:
                    return 'Zgodovina naročil';
                // case WorkerDetailsTabType.WORKER_COMMUNICATION:
                //     return 'Komunikacija z delavcem';
                default:
                    return value;
            }
        }
        return '(Ni podatka)';
    }
}
