<div class="flex-grow-1">
    <div *ngIf="!initFetchCompleted; else fetchCompletedBlock" class="pt-4 pb-3 chat-container">
        <com-inline-loading [onlyIcon]="true"></com-inline-loading>
    </div>
    <ng-template #fetchCompletedBlock>
        <div class="pt-4 pb-2 chat-container">
            <div class="">
                <div *ngIf="attachments?.length === 0" class="mt-1 text-center">
                    <i>Ni komunikacije.</i>
                </div>
                <!-- @TODO: Ignored manually on 2023-10-13 -->
                <!-- eslint-disable @angular-eslint/template/use-track-by-function -->
                <div *ngFor="let element of attachments">
                    <!-- eslint-enable -->
                    <div *ngIf="element.type === 'comment'">
                        <div class="py-1" [ngClass]="!element.local ? 'row' : 'row   justify-content-end'">
                            <div>
                                <div>
                                    <strong style="padding-right: 10px" [ngClass]="{ 'text-primary': element.local }">
                                        {{ element.value.comentatorName }}
                                    </strong>
                                    <span container="body" class="element__date">{{ element.dateAdded | date: 'dd.MM.yyyy' }}</span>
                                </div>
                                <div [ngClass]="!element.local ? 'local-comment' : 'external-comment'">
                                    {{ element.value.comment | valueChecker }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="element.type === 'document'">
                        <div class="py-1" [ngClass]="!element.local ? 'row  ' : 'row justify-content-end'">
                            <div class="pt-2">
                                <div>
                                    <strong style="padding-right: 10px" [ngClass]="{ 'text-primary': element.local }">
                                        {{ element.value.appUserName }}
                                    </strong>
                                    <span container="body" class="element__date">{{ element.dateAdded | date: 'dd.MM.yyyy' }}</span>
                                </div>
                                <div class="d-flex align-items-center" [ngClass]="!element.local ? 'local-document' : 'external-document'">
                                    <i class="las la-2x la-file-medical pr-1"></i>
                                    <span class="mfa-document-name pr-2">{{ element?.value?.description }}</span>
                                    <i (click)="downloadDocument(element)" ngbTooltip="Ogled" container="body" class="las la-eye mf-pointer"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <div class="m-portlet__foot pl-3 pr-3 pb-4">
            <div class="upload">
                <form [formGroup]="communicationForm" (ngSubmit)="onSubmitForm()" novalidate>
                    <div class="m-list-search">
                        <div class="m-list-search__results w-100">
                            <div class="m-list-search__result-item">
                                <span class="m-list-search__result-item-text">
                                    <textarea
                                        formControlName="message"
                                        rows="2"
                                        id="commentInput"
                                        placeholder="Vnesite svoje sporočilo..."
                                        class="form-control m-input"
                                    ></textarea>
                                </span>
                            </div>
                            <div class="m-list-search__result-item my-3">
                                <file-upload [multiple]="false" formControlName="uploadFile" animation="false" class="file-upload border">
                                    <ng-template #placeholder let-isFileDragDropAvailable="isFileDragDropAvailable">
                                        <div class="btn btn-outline-primary btn-sm m-btn mf-pointer">
                                            <span>
                                                <i class="las la-plus"></i>
                                                <span>Pripnite datoteko</span>
                                            </span>
                                        </div>
                                    </ng-template>
                                    <ng-template #item let-i="index" let-file="file" let-control="control">
                                        <div class="file-info clearfix">
                                            <i class="las la-file-medical mr-1"></i>
                                            <span class="file-name">{{ file.name }}</span>

                                            <a (click)="control.removeFile(file)" class="float-right"><i class="las la-times text-primary"></i></a>
                                        </div>
                                    </ng-template>
                                </file-upload>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button type="submit" class="btn btn-outline-primary m-btn btn-block">
                            <span>
                                <span>POŠLJI</span>
                            </span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </ng-template>
</div>
