export const environment = {
    production: true,
    AUTHPASS: btoa('test:test'),
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    VERSION: require('../../package.json').version,
    reconnectInterval: 2000,
    INTERCOM_KEY: 'ky5rkdlu',
    RELEASE_STAGE: 'production',
    auth: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/oauth/token',
    },
    captcha: {
        url: '/captcha',
    },
    root: {
        v1: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/',
            port: '',
            url: '/booking',
        },
        v2: {
            protocol: 'http://',
            rootUrl: 'app.eambulanta.si/',
            port: '',
            url: '/booking',
        },
        v3: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/',
            port: '',
            url: '/booking',
        },
    },

    assistance: {
        v1: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/assistance/api',
        },
        v2: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/v2/assistance/api',
        },
        v3: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/asv2/assistance/api',
        },
    },

    network: {
        v1: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/network',
        },
    },
    ea: {
        v1: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/erp/api',
        },
        v2: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/eav2/erp/api',
        },
    },
    preventivni: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/asv2/preventivni/api',
    },
    preventivniGateway: {
        protocol: 'https://',
        rootUrl: 'api.medifit.si',
        port: '',
        url: '/preventivni'
    }
};
