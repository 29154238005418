import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { BaseState } from './../state/base/base.state';

@Injectable({
    providedIn: 'root',
})
export class RulesService {
    constructor(
        private permissionsService: NgxPermissionsService,
        private store: Store,
    ) {}

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private allRules: any = {
        ROLE_NET_USER: [],
        ROLE_NET_OWNER: [],
        ROLE_BOOKING_MASTER: [],
    };

    public setRules(): void {
        const roles = this.store.selectSnapshot(BaseState.getUser)?.roles;
        if (roles === null) {
            return;
        }
        if (roles) {
            const userPermissions: string[] = [];
            roles.map(el => {
                userPermissions.push(...(this.allRules[el] || []));
            });
            this.permissionsService.loadPermissions(userPermissions);
        }
    }

    public getAllRules(): NgxPermissionsObject {
        return this.permissionsService.getPermissions();
    }
}
