import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MenuState } from './../../../_common/state/menu/menu.state';

@Component({
    selector: 'lay-header',
    templateUrl: './lay-header.component.html',
    styleUrls: ['./lay-header.component.scss'],
})
export class LayHeaderComponent implements OnInit {
    constructor(private store: Store) {}
    public headerInfoData?: { iconClass: string; title: string; description: string };
    private onDestroy$ = new Subject<void>();
    public offlineEvent: Observable<Event>;
    public onlineEvent: Observable<Event>;
    public isConnectionOk = true;

    ngOnInit(): void {
        this.handleAppConnectivityChanges();
        this.store.select(MenuState.getHeaderInfo).subscribe(headerInfo => {
            this.headerInfoData = headerInfo;
        });
    }
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy() {
        this.onDestroy$.next();
    }
    private handleAppConnectivityChanges(): void {
        this.onlineEvent = fromEvent(window, 'online');
        this.offlineEvent = fromEvent(window, 'offline');

        merge(this.onlineEvent, this.offlineEvent)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(e => {
                if (e?.type === 'online') this.isConnectionOk = true;
                if (e?.type === 'offline') this.isConnectionOk = false;
            });
    }
}
