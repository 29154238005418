<ng-container *ngIf="settings">
    <ng-container *ngIf="type === 'badge'; else withText">
        <!-- ----- Only Badge ------------------------- -->
        <span
            [placement]="settings.placement"
            [ngbTooltip]="settings.tooltip"
            container="body"
            style="opacity: 0.75"
            class="m-badge"
            [ngClass]="settings.class"
        ></span>
    </ng-container>
    <ng-template #withText>
        <!-- ----- Badge With Text ------------------------- -->
        <span container="body" placement="right" style="opacity: 0.75" class="m-badge p-1" [ngClass]="settings.class">
            {{ settings?.tooltip | uppercase }}
        </span>
    </ng-template>
</ng-container>
