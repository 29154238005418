import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'lay-menu',
    templateUrl: './lay-menu.component.html',
    styleUrls: ['./lay-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LayMenuComponent implements OnInit {
    @Output()
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    onMenuSizeChange: EventEmitter<boolean> = new EventEmitter();

    public isMin = false;
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit() {}

    minMenu(value: boolean): void {
        this.isMin = value;
        this.onMenuSizeChange.emit(this.isMin);
    }
}
