// @TODO: Ignored with eslint-interactive on 2023-09-15
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MenuStateActions {
    export class SetHeaderData {
        public static readonly type = '[Menu] SetHeaderData';
        constructor(public readonly headerData: { iconClass: string; title: string; description: string }) {}
    }
    export class ClearHeaderData {
        public static readonly type = '[Menu] ClearHeaderData';
        // @TODO: Ignored with eslint-interactive on 2023-09-15
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        constructor() {}
    }
}
