<ng-container *ngIf="bgPanel">
    <div class="bg-accent p-2 ref-view-container">
        <ng-container [ngTemplateOutlet]="documentTemplate"></ng-container>
    </div>
</ng-container>

<ng-container *ngIf="!bgPanel">
    <div class="ref-view-container-plain"><ng-container [ngTemplateOutlet]="documentTemplate"></ng-container></div>
</ng-container>

<ng-template #documentTemplate>
    <div (click)="openDocument()" class="h-100 overflow-auto" [ngClass]="{ 'p-3': !bgPanel, 'document-template-padding': bgPanel }">
        <!-- <div *ngIf="allPages > 1" class="d-flex justify-content-end mt-1 mr-1 pt-2">
            <ngb-pagination
                [(page)]="currentPage"
                [collectionSize]="allPages"
                [pageSize]="1"
                [directionLinks]="allPages > 4"
                [maxSize]="4"
                class="d-flex"
            ></ngb-pagination>
        </div> -->
        <div *ngIf="isLoading" class="w-100 d-flex h-100 loading-indicator-referral">
            <div role="status" class="spinner-border m-auto">
                <span class="sr-only">Nalagam...</span>
            </div>
        </div>
        <pdf-viewer
            [id]="'referralViewer'"
            [class.border]="showBorder"
            [src]="documentFile"
            [show-all]="true"
            [original-size]="false"
            (after-load-complete)="onAfterLoad($event)"
            style="display: block"
            class="pdf-viewer-com"
            [ngClass]="{ 'no-events': isLoading }"
        ></pdf-viewer>
    </div>
</ng-template>
