import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyFursData } from '../../_common/model/companyFursData.model';
import { Employer } from '../../_common/model/employer';
import { ZipCode } from '../../_common/model/zip-code.model';
import { ApiUrlService } from '../../_common/services/api-url.service';
import { HttpClientService } from '../../_common/services/http-client.service';
import { GET_EMPLOYER, UPDATE_EMPLOYER } from './employers.graphql';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    constructor(
        private helperUrl: ApiUrlService,
        private httpClient: HttpClientService,
        private apollo: Apollo,
    ) {}

    public searchCompanyData(search: string): Observable<CompanyFursData[]> {
        return this.httpClient.get(this.helperUrl.getPreventivniUrl() + `/companies/search?query=${search}`);
    }

    public getZipCodes(): Observable<ZipCode[]> {
        return this.httpClient.get(this.helperUrl.getEambulantaApi() + 'zipcodes');
    }

    public getEmployer(id: string): Observable<Employer> {
        return this.apollo
            .query({
                query: GET_EMPLOYER,
                variables: { id },
                fetchPolicy: 'no-cache',
            })
            .pipe(
                map(({ data: { employer }, errors }) => {
                    if (errors) {
                        throw errors;
                    }
                    return employer;
                }),
            );
    }

    public updateEmployer(id: string, employer: Employer): Observable<Employer> {
        return this.apollo
            .mutate({
                mutation: UPDATE_EMPLOYER,
                variables: { id, employer },
            })
            .pipe(
                map(({ data, errors }) => {
                    if (errors) {
                        throw errors;
                    }
                    if (!data?.updateEmployer) {
                        throw new Error('Error updating employer');
                    }
                    return data.updateEmployer;
                }),
            );
    }
}
