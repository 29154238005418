import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PipesModule } from 'src/app/_common/pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';
import { WorkGroupDetailsComponent } from './components/work-group-details/work-group-details.component';
import { WorkGroupsTemplateComponent } from './work-groups-template/work-groups-template.component';
import { WorkGroupsComponent } from './working-groups.component';

const routes: Routes = [
    {
        path: '',
        component: WorkGroupsTemplateComponent,
        children: [
            {
                path: '',
                component: WorkGroupsComponent,
            },
        ],
    },
];

@NgModule({
    declarations: [WorkGroupsTemplateComponent, WorkGroupsComponent, WorkGroupDetailsComponent],
    imports: [CommonModule, RouterModule.forChild(routes), PipesModule, ReactiveFormsModule, NgbModule, SharedModule, NgxDatatableModule],
})
export class WorkingGroupsModule {}
