import { AbstractControl, Validators } from '@angular/forms';

export class AppDateValidators {
    static uiDateFormatRegex = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/;
    static datePatternValidator(datePattern: RegExp, allowEmptyOrNull = true) {
        return (control: AbstractControl) => {
            if (allowEmptyOrNull && (!control.value || control.value === '')) {
                return null;
            }
            return Validators.pattern(datePattern)(control);
        };
    }
}
