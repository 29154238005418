import { HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentResponse } from '../model/documentResponse.model';
import { ApiUrlService } from './api-url.service';
import { HttpClientService } from './http-client.service';

@Injectable({
    providedIn: 'root',
})
export class DocumentsService {
    constructor(
        private httpClient: HttpClientService,
        private helperUrl: ApiUrlService,
    ) {}

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public uploadFile(file: File, comment: string, bsid: number): Observable<DocumentResponse | HttpEventType | any> {
        return this.httpClient.uploadFile(this.helperUrl.getAssistanceUrl(2) + `upload/bs/${bsid}/`, file, comment);
    }

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public uploadContractorFile(file: File, comment: string, contractorId: number): Observable<DocumentResponse | HttpEventType | any> {
        return this.httpClient.uploadFile(this.helperUrl.getDocumentUrl(`upload/contractors/${contractorId}/`), file, comment);
    }

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public openDocument(bsid: number, documentId: string): Observable<DocumentResponse | HttpEventType | any> {
        return this.httpClient.getAttachment(this.helperUrl.getAssistanceUrl(2) + `bs/${bsid}/documents/${documentId}`);
    }

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getContractorDocument(contractorId: number, documentId: string): Observable<DocumentResponse | HttpEventType | any> {
        return this.httpClient.getAttachment(this.helperUrl.getDocumentUrl(`contractors/${contractorId}/documents/${documentId}`));
    }

    // --url https://test-app.eambulanta.si/api/booking/booking/v2/assistance/api/preventiveexamform/preview  \
    // http://10.230.33.13:8092/booking/v2/assistance/api/preventiveexamform/preview
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getReferralDocument(referralId: string): Observable<DocumentResponse | HttpEventType | any> {
        return this.httpClient.getAttachmentPost(this.helperUrl.getPreventivniGatewayUrl() + '/documents/referral/preview', {
            referralId: referralId,
        });
        // return this.httpClient.getAttachmentPost(this.helperUrl.getAssistanceUrl(2, `preventiveexamform/preview`), data);
    }

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getDocumentThumbnails(contractorId: number, documentId: string, height: number, width?: number): Observable<DocumentResponse | any> {
        let url = '';
        if (height && width) {
            url += `?width=${width}&height=${height}`;
        } else if (height) {
            url += `?height=${height}`;
        } else if (width) {
            url += `?width=${width}`;
        }
        return this.httpClient.getAttachment(this.helperUrl.getDocumentUrl(`contractors/${contractorId}/documents/${documentId}/thumbnail${url}`));
        // if (width && !height) {
        //     return this.httpClient.getAttachment(
        //         this.helperUrl.getDocumentUrl(
        //             `/contractors/${contractorId}/documents/documentId/thumbnail?width=${width}`
        //         )
        //     );
        // } else if (height && !width) {
        //     return this.httpClient.getAttachment(
        //         this.helperUrl.getDocumentUrl() +
        //             '/contractors/' +
        //             contractorId +
        //             '/documents/' +
        //             documentId +
        //             `/thumbnail?height=${height}`
        //     );
        // } else {
        //     return this.httpClient.getAttachment(
        //         this.helperUrl.getDocumentUrl() +
        //             '/contractors/' +
        //             contractorId +
        //             '/documents/' +
        //             documentId +
        //             `/thumbnail?width=${width}&height=${height}`
        //     );
        // }
    }

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public combineMultipleDocuments(bsId: number, caseId: string, documentIds: string[]): Observable<DocumentResponse | HttpEventType | any> {
        return this.httpClient.getAttachmentPost(this.helperUrl.getAssistanceUrl(2, `bs/${bsId}/cases/${caseId}/documents/join`), documentIds);
    }

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getDocument(bsid: number, documentId: number | string): Observable<DocumentResponse | HttpEventType | any> {
        return this.httpClient.getAttachment(this.helperUrl.getAssistanceUrl(2) + `bs/${bsid}/documents/${documentId}`);
    }
}
