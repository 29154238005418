import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BehaviorSubject, EMPTY, Observable, switchMap } from 'rxjs';
import { catchError, filter, map, startWith, withLatestFrom } from 'rxjs/operators';
import { BaseState } from 'src/app/_common/state/base/base.state';
import { CaseStatus } from '../../../../../_common/enums/caseStatus.enum';
import { Employee } from '../../../../../_common/model/employee';
import { ReservationDetails } from '../../../../reservations/model/reservation-details';
import { WorkersService } from '../../../workers.service';

@Component({
    selector: 'worker-reservations',
    templateUrl: './worker-reservations.component.html',
    styleUrls: ['./worker-reservations.component.scss'],
})
export class WorkerReservationsComponent {
    private workerDataSubject = new BehaviorSubject<Employee | null>(null);
    public workerCases$ = this.getWorkerCases$();
    @Input() set workerData(value: Employee) {
        this.workerDataSubject.next(value);
    }

    constructor(
        private workerRest: WorkersService,
        private store: Store,
        private router: Router,
    ) {}

    public getWorkerCases$(): Observable<ReservationDetails[]> {
        return this.workerDataSubject.pipe(
            filter(workerData => workerData?.medifitCustomerId != null),
            withLatestFrom(this.store.select(BaseState.getBSId)),
            switchMap(([workerData, bsId]) => this.workerRest.getBusinessSubjectCasesForWorker(bsId, workerData?.medifitCustomerId as number)),
            map(cs => cs.filter(c => c.caseStatus !== CaseStatus.NO_RESERVATIONS)),
            catchError(() => EMPTY),
            startWith([]),
        );
    }

    public caseSelected({ selected }: { selected: ReservationDetails[] }) {
        this.router.navigate([`reservations-list/reservation/${selected[0]?.caseId}`]);
    }
}
