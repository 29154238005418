import { HttpEventType } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import * as FileSaver from 'file-saver';
import { forkJoin, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { BaseState } from 'src/app/_common/state/base/base.state';
import { ResDocument } from '../../model/res-document';
import { ReservationDetails } from '../../model/reservation-details';
import { DocumentResponse } from './../../../../_common/model/documentResponse.model';
import { DocumentsService } from './../../../../_common/services/documents.service';
import { ReservationsService } from './../../reservations.service';

@Component({
    selector: 'res-reservation-documents',
    templateUrl: './res-reservation-documents.component.html',
    styleUrls: ['./res-reservation-documents.component.scss'],
})
export class ResReservationDocumentsComponent implements OnInit {
    constructor(
        private reservationRest: ReservationsService,
        private documentApi: DocumentsService,
        private store: Store,
    ) {}

    private bsId = this.store.selectSnapshot(BaseState.getBSId);
    private onDestroy$ = new Subject<void>();
    public documentId?: string;
    public referral?: ResDocument;
    public report?: ResDocument;
    public initFetchCompleted = false;

    @Input() documentType: 'REFERRAL' | 'REPORT';
    @Input() reservationDetails: ReservationDetails;

    ngOnInit(): void {
        this.initDocuments();
    }

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    public initDocuments() {
        return forkJoin({
            referral: this.reservationRest.getCaseDocuments(this.bsId, this.reservationDetails.caseId).pipe(
                takeUntil(this.onDestroy$),
                map(referralDocuments => {
                    if (referralDocuments.length > 1) {
                        referralDocuments.sort((a: ResDocument, b: ResDocument) => {
                            let result = 1;
                            if (a.documentAdded < b.documentAdded) {
                                result = -1;
                            }
                            return result;
                        });
                    }
                    if (referralDocuments.length < 1) {
                        return undefined;
                    }
                    return referralDocuments[0];
                }),
            ),
            report: this.reservationRest.getCaseContractorDocuments(this.bsId, this.reservationDetails.caseId).pipe(
                takeUntil(this.onDestroy$),
                map(reportDocuments => {
                    if (reportDocuments.length > 1) {
                        reportDocuments.sort((a: ResDocument, b: ResDocument) => {
                            let result = 1;
                            if (a.documentAdded > b.documentAdded) {
                                result = -1;
                            }
                            return result;
                        });
                    }
                    if (reportDocuments.length < 1) {
                        return undefined;
                    }
                    return reportDocuments[0];
                }),
            ),
        }).subscribe(
            (documentResults: { referral?: ResDocument; report?: ResDocument }) => {
                this.referral = documentResults.referral;
                this.report = documentResults.report;
                this.documentId = this.documentType === 'REFERRAL' ? this.referral?.documentId : this.report?.documentId;
                this.initFetchCompleted = true;
            },
            () => (this.initFetchCompleted = true),
        );
    }

    public downloadDocument(type: 'REPORT' | 'REFERRAL'): void {
        const document = type === 'REFERRAL' ? this.referral : this.report;
        if (document?.documentId) {
            this.documentApi
                .openDocument(this.bsId, document?.documentId)
                .pipe(
                    takeUntil(this.onDestroy$),
                    filter(event => event.type === HttpEventType.Response),
                    map((res: DocumentResponse) => {
                        return { document: new Blob([res.body], { type: res.body.type }) };
                    }),
                )
                .subscribe(result => {
                    FileSaver.saveAs(
                        result?.document,
                        `${type == 'REPORT' ? 'Zdravnisko_spricevalo_' : 'Napotnica_'}${this.reservationDetails?.caseId}`,
                    );
                });
        }
    }

    public downloadReferralAndReport() {
        if (this.referral?.documentId && this.report?.documentId) {
            const documentIds: string[] = [this.referral.documentId, this.report.documentId];
            return this.documentApi
                .combineMultipleDocuments(this.bsId, this.reservationDetails.caseId, documentIds)
                .pipe(
                    takeUntil(this.onDestroy$),
                    filter(event => event.type === HttpEventType.Response),
                    map((res: DocumentResponse) => {
                        return { document: new Blob([res.body], { type: res.body.type }) };
                    }),
                )
                .subscribe((docReq: { document: Blob | string }) => {
                    FileSaver.saveAs(docReq?.document, `Napotnica_Spricevalo_${this.reservationDetails.caseId}_.pdf`);
                });
        }
    }
}
