import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}
    public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public setLoading(loading: boolean = true) {
        this.isLoading$.next(loading);
    }
}
