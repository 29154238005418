import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ErrorNotifyService } from './error-notify.service';
@Injectable({
    providedIn: 'root',
})
export class MfToasterService {
    constructor(
        private toastr: ToastrService,
        private errorNotify: ErrorNotifyService,
    ) {}

    public success(text: string): void {
        this.toastr.success(text);
    }

    public warning(text: string): void {
        this.toastr.warning(text);
    }

    public error(text: string): void {
        this.toastr.error(text);
    }

    public info(text: string): void {
        this.toastr.info(text);
    }

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public errorAndSend(text: string, errorObject: any, showToast: boolean = true): void {
        if (showToast) this.toastr.error(text);
        this.errorNotify.sendErrorManualy(errorObject);
    }
}
