import { NgModule } from '@angular/core';
import { ApolloClientOptions, DefaultOptions, InMemoryCache } from '@apollo/client/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../environments/environment';

const defaultOptions: DefaultOptions = {
    watchQuery: {
        // fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        // fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
};

// @TODO: Ignored with eslint-interactive on 2023-09-15
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
    const { protocol, rootUrl, port, url } = environment.preventivniGateway;
    const uri = `${protocol}${rootUrl}${port}${url}/graphql`;
    return {
        link: httpLink.create({ uri }),
        cache: new InMemoryCache(),
        defaultOptions,
    };
}

@NgModule({
    exports: [ApolloModule],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink],
        },
    ],
})
export class GraphQLModule {}
