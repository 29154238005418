import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HelperService } from '../services/helper.service';

@Injectable()
export class AuthGuard implements CanActivateChild {
    constructor(
        private router: Router,
        private helper: HelperService,
    ) {}

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (this.helper.getTokenDirectFromStorage()) {
            return true;
        }

        this.router.navigate(['/public/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
