<div class="flex-grow-1">
    <div *ngIf="!initFetchCompleted; else fetchCompletedBlock" class="py-5">
        <com-inline-loading [onlyIcon]="true"></com-inline-loading>
    </div>
    <ng-template #fetchCompletedBlock>
        <div class="body d-xl-flex d-sm-inline">
            <div class="col-12">
                <div class="py-3 row border-bottom">
                    <div class="col-12">
                        <div class="col-12">
                            <h5>Oddano naročilo</h5>
                        </div>
                        <div class="d-flex align-items-center">
                            <label class="col-form-label pr-2 font-weight-bold col-3">Id naročila:</label>
                            <div class="flex-grow-1">
                                {{ reservation?.caseId | valueChecker }}
                            </div>
                        </div>

                        <div class="d-flex align-items-center">
                            <label class="col-form-label pr-2 font-weight-bold col-3">Datum naročila:</label>
                            <div class="flex-grow-1">
                                {{ reservation?.caseDate | date: 'dd.MM.yyyy HH:mm' | valueChecker }}
                            </div>
                        </div>

                        <div class="d-flex align-items-center">
                            <label class="col-form-label pr-2 font-weight-bold col-3">Datum termina:</label>
                            <div *ngIf="status?.eventType !== 'WQ_ITEM_CREATED'; else waitingQueue" class="mr-1">
                                {{ status?.reservationDate || status?.eventDate | date: 'dd.MM.yyyy HH:mm' | valueChecker }}
                            </div>
                            <ng-template #waitingQueue>
                                Čakalna vrsta
                                <i ngbTooltip="Termin bo določil izvajalec" class="las la-info-circle ml-1"></i>
                            </ng-template>
                            <div *ngIf="canCancel">
                                <button (click)="cancelCase()" class="btn p-2 font--small">
                                    <i class="las la-edit"></i>
                                    PREKLIČI
                                </button>
                            </div>
                        </div>

                        <div class="d-flex align-items-center pb-3">
                            <label class="col-form-label pr-2 font-weight-bold col-3">Status termina:</label>
                            <div class="flex-grow-1">
                                <com-reservation-status
                                    *ngIf="reservation"
                                    [setData]="{
                                        caseStatus: status?.eventType,
                                        canceled: reservation?.canceled,
                                        isDocumentUploaded: reportDocumentId?.length > 0,
                                        eventDate: status?.reservationDate || status?.eventDate
                                    }"
                                ></com-reservation-status>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="py-3">
                    <div class="col-12">
                        <h5>Zdravstvena obravnava</h5>
                    </div>
                    <div class="d-flex align-items-center">
                        <label class="col-form-label pr-2 font-weight-bold col-3">Delavec:</label>
                        <ng-template #customerName class="flex-grow-1">
                            <button
                                [disabled]="(customerLink$ | async) === null"
                                [routerLink]="customerLink$ | async"
                                class="btn m-btn m-badge p-1 px-2 font--small"
                                [ngClass]="{ 'm-btn--hover-brand': customerLink$ | async, 'disable-hover': (customerLink$ | async) === null }"
                            >
                                {{ reservation?.customer?.name }}
                                {{ reservation?.customer?.surname }}
                            </button>
                        </ng-template>
                        <div *ngIf="!reservation?.customer?.name && !reservation?.customer?.surname; else customerName">(Ni podatka)</div>
                    </div>

                    <div class="d-flex align-items-center">
                        <label class="col-form-label pr-2 font-weight-bold col-3">Naročen v ambulanto:</label>
                        <div class="flex-grow-1">
                            {{ reservation?.contractor?.name | valueChecker }}
                        </div>
                    </div>

                    <div class="d-flex align-items-center">
                        <label class="col-form-label pr-2 font-weight-bold col-3">Naslov:</label>
                        <div class="flex-grow-1">
                            {{
                                reservation?.contractor?.contractorOrgUnitAddress
                                    ? reservation?.contractor?.contractorOrgUnitAddress
                                    : (reservation?.contractor?.contractorAddress | valueChecker)
                            }}
                        </div>
                    </div>

                    <div class="d-flex align-items-center pb-3">
                        <label class="col-form-label pr-2 font-weight-bold col-3">Zdravniško spričevalo:</label>
                        <div class="flex-grow-1">
                            <ng-container *ngIf="!reportDocumentId; else loaded">
                                <span style="opacity: 0.75" class="m-badge m-badge--secondary p-1">ŠE NI NALOŽENO</span>
                            </ng-container>
                            <ng-template #loaded>
                                <span style="opacity: 0.75" class="m-badge m-badge--success p-1">SPRIČEVALO PREJETO</span>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>
