import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { iif, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseStateActions } from 'src/app/_common/state/base/base.actions';
import { BaseState } from 'src/app/_common/state/base/base.state';
import { MenuStateActions } from 'src/app/_common/state/menu/menu.actions';
import { ResReservationDetailsComponent } from '../reservations/res-reservation-details/res-reservation-details.component';
import { NotificationElement } from './../../_common/model/notificationElement';
import { NavigateService } from './../../_common/services/navigate.service';
import { NotificationsService } from './../../_common/services/notifications.service';

@Component({
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit {
    constructor(
        private store: Store,
        private router: Router,
        private notifRest: NotificationsService,
        private navigate: NavigateService,
    ) {}

    private bsId = this.store.selectSnapshot(BaseState.getBSId);
    public allNotifications: NotificationElement[] = [];
    private onDestroy$ = new Subject<void>();
    public limit = 12;

    @HostListener('window:resize', ['$event'])
    onResize() {
        if (window.innerWidth > 0) {
            this.limit = Math.floor((window.innerHeight - 230) / 50);
        }
    }

    ngOnInit() {
        this.store.dispatch(
            new MenuStateActions.SetHeaderData({
                iconClass: 'las la-calendar-check',
                title: 'preventivni.si',
                description: ' ',
            }),
        );
        this.getNotifications();
        this.limit = Math.floor((window.innerHeight - 230) / 50);
    }

    public getNotifications() {
        this.notifRest
            .getNotifications(this.bsId, moment().subtract(1, 'month').format('YYYY-MM-DD'), moment().add(1, 'day').format('YYYY-MM-DD'))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(notifications => {
                this.allNotifications = this.notifRest.prepareNotifications(notifications);
            });
    }

    public notificationSelected({ selected }: { selected: NotificationElement[] }) {
        iif(() => !selected[0].seen, this.notifRest.markNotificationAsSeen(this.bsId, selected[0]?.id), of(true))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
                selected[0].seen = true;
                this.navigate.openSidePanel({
                    component: ResReservationDetailsComponent,
                    params: {
                        setData: { reservationDetails: { caseId: selected[0]?.data?.caseId }, selectedTab: selected[0]?.tab },
                    },
                    title: '',
                });
            });
    }

    logout() {
        this.store.dispatch(BaseStateActions.Common.ClearBaseState).subscribe(() => this.router.navigate(['/']));
        this.router.navigate(['/public/login']);
    }

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.navigate.closeSidePanel();
    }

    getRowClass(row: NotificationElement): string {
        if (row.seen) return 'seen';
        return 'not-seen';
    }
}
