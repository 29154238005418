import { Injectable } from '@angular/core';
import { MetabaseAnalyticsService } from '@meddev/fe-shared';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import { mergeMap, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { BaseState } from '../state/base/base.state';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    public metabaseAnalyticsDashboards$ = this.getMetabaseAnalyticsDashboards();

    constructor(
        private metabase: MetabaseAnalyticsService,
        private store: Store,
    ) {}

    private getMetabaseAnalyticsDashboards() {
        return this.store.select(BaseState.getToken).pipe(
            distinctUntilChanged(_.isEqual),
            mergeMap(token =>
                this.store.select(BaseState.getBS).pipe(
                    distinctUntilChanged(_.isEqual),
                    map(bs => {
                        return { token, bs };
                    }),
                ),
            ),
            mergeMap(({ token, bs }) => {
                if (!token?.sdata?.atoken || !bs || bs.id === -1) {
                    return of({ groups: [] });
                }
                return this.metabase.getAnalyticsConfig(bs.id, token.sdata.atoken);
            }),
            map(config => {
                return _.flatten(
                    config.groups.map(group => {
                        return group.views;
                    }),
                );
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }
}
