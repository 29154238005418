<div class="mf-content">
    <div class="mf-o-worker-referrals pt-4">
        <ngx-datatable
            [rows]="workerCases$ | async"
            [columnMode]="'flex'"
            [rowHeight]="'auto'"
            [footerHeight]="50"
            [selectionType]="'single'"
            [limit]="11"
            [messages]="{ emptyMessage: 'Ni podatkov za prikaz.', totalMessage: 'skupaj' }"
            (select)="caseSelected($event)"
            class="bootstrap datatable--border"
        >
            <ngx-datatable-column [maxWidth]="90" [sortable]="false" [flexGrow]="1" name="STATUS">
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                    <com-reservation-status
                        [type]="'badge'"
                        [setData]="{
                            caseStatus: row.caseStatus,
                            canceled: row.canceled,
                            isDocumentUploaded: row.contractorDocuments?.documents?.length > 0
                        }"
                    ></com-reservation-status>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [flexGrow]="1" name="DATUM NAROČILA" prop="caseDate">
                <ng-template ngx-datatable-cell-template let-value="value">
                    {{ value | date: 'dd. MM. yyyy HH:mm' }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [sortable]="false" [flexGrow]="1" name="DATUM TERMINA" prop="reservations">
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                    <span *ngIf="row.caseStatus === 'WAITING_QUEUE' || row.caseCanceled === true; else termDate">
                        <span *ngIf="row.caseStatus === 'WAITING_QUEUE'; else canceled">V čakalni vrsti</span>
                        <ng-template #canceled>Termin preklican</ng-template>
                    </span>
                    <ng-template #termDate>
                        {{ value[0]?.eventDate || row?.selectedSeparatedDate | date: 'dd.MM.yyyy HH:mm' | valueChecker }}
                    </ng-template>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [flexGrow]="1" name="IZVAJALEC" prop="contractor">
                <ng-template ngx-datatable-cell-template let-value="value">
                    <span *ngIf="value?.name?.length > 17; else normalText" container="body" ngbTooltip="{{ value?.name }}">
                        {{ value?.name | slice: 0 : 14 }} ...
                    </span>
                    <ng-template #normalText>
                        {{ value?.name | valueChecker }}
                    </ng-template>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [flexGrow]="1" name="VRSTA PREGLEDA" prop="services">
                <ng-template ngx-datatable-cell-template let-value="value">
                    <span *ngIf="value[0]?.name?.length > 19; else normalServiceText" container="body" ngbTooltip="{{ value[0]?.name }}">
                        {{ value[0]?.name | slice: 0 : 16 }} ...
                    </span>
                    <ng-template #normalServiceText>
                        {{ value[0]?.name | valueChecker }}
                    </ng-template>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>
