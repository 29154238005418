import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { CaseStatus } from '../../enums/caseStatus.enum';

export interface StatusSettings {
    caseStatus: CaseStatus;
    canceled?: boolean;
    isDocumentUploaded?: boolean;
    eventDate: Date;
}

@Component({
    selector: 'com-reservation-status',
    templateUrl: './com-reservation-status.component.html',
    styleUrls: ['./com-reservation-status.component.scss'],
})
export class ComReservationStatusComponent implements OnInit {
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    @Input() type: 'badge' | 'badgeWithText' = 'badgeWithText';
    @Input() set setData(value: StatusSettings) {
        if (value) {
            this.initSettings(value);
        }
    }

    public settings: {
        class: string;
        placement: string;
        tooltip: string;
        statusText: string;
    };

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit(): void {}

    public initSettings(value: StatusSettings) {
        this.settings = {
            class: this.getClass(value),
            placement: 'right',
            tooltip: this.getText(value),
            statusText: this.getText(value),
        };
    }

    public getClass(value: StatusSettings): string {
        if (value?.canceled) {
            return this.type === 'badge' ? 'm-badge--dark--outline' : 'm-badge--dark';
        } else if (value?.isDocumentUploaded) {
            return 'm-badge--success';
        } else {
            switch (value?.caseStatus) {
                case CaseStatus.WQ_ITEM_CREATED:
                case CaseStatus.WAITING_QUEUE:
                    return 'm-badge--warning';
                case CaseStatus.PARTIALLY_OK:
                case CaseStatus.RESERVATION_CREATED:
                    if (this.type === 'badge') {
                        return 'm-badge--accent';
                    }
                    return moment(value?.eventDate).isAfter(moment()) ? 'm-badge--accent' : ' m-badge--success--outline';
                case CaseStatus.RESERVATION_CANCELED:
                    return this.type === 'badge' ? 'm-badge--dark--outline' : 'm-badge--dark';
                case CaseStatus.CASE_CONCLUDED:
                case CaseStatus.PARTIALLY_NOT_OK:
                case CaseStatus.VISIT_CONCLUDED:
                    return 'm-badge--success--outline';
                case CaseStatus.NO_RESERVATIONS:
                default:
                    return 'm-badge--secondary';
            }
        }
    }
    public getText(value: StatusSettings): string {
        if (value?.canceled) {
            return 'Termin preklican';
        } else if (value?.isDocumentUploaded) {
            return 'Spričevalo prejeto';
        } else {
            switch (value?.caseStatus) {
                case CaseStatus.RESERVATION_CANCELED:
                    return 'Termin preklican';
                case CaseStatus.RESERVATION_CREATED:
                    return moment(value?.eventDate).isAfter(moment()) ? 'Termin rezerviran' : 'Termin zaključen';
                case CaseStatus.WQ_ITEM_CREATED:
                case CaseStatus.WAITING_QUEUE:
                    return 'V čakalni vrsti';
                case CaseStatus.PARTIALLY_OK:
                    return 'Termin rezerviran';
                case CaseStatus.CASE_CONCLUDED:
                case CaseStatus.PARTIALLY_NOT_OK:
                case CaseStatus.VISIT_CONCLUDED:
                    return 'Termin zaključen';
                case CaseStatus.NO_RESERVATIONS:
                default:
                    return 'Napotnica v izdelavi';
            }
        }
    }
}
