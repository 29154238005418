import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[href]',
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { '(click)': 'preventDefault($event)' },
})
export class HrefPreventDefaultDirective implements AfterViewInit {
    @Input() href?: string;

    constructor(private el: ElementRef) {}
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngAfterViewInit() {}
    preventDefault(event: Event) {
        if (this.href && (this.href.length === 0 || this.href === '#')) {
            event.preventDefault();
        }
    }
}
