<div>
    <div class="row mf-o-wg-create">
        <div class="col-10 my-3 d-flex">
            <div class="pt-2 mf-men-header_book-max pl-2">
                <div (click)="addNewWorkGroup()" class="mf-btn-arrow">
                    <span>
                        <i class="las la-plus"></i>
                        USTVARI NOVO
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="pt-3 p-2">
        <form [formGroup]="searchForm" class="pb-3 form">
            <input type="text" formControlName="name" placeholder="Iskanje ..." class="form-control m-input" />
        </form>
        <div class="m-portlet">
            <div class="m-portlet__body mf-o-wg-table">
                <ngx-datatable
                    #mainTable
                    [rows]="workingGroups"
                    [columnMode]="'flex'"
                    [rowHeight]="'auto'"
                    [footerHeight]="50"
                    [externalSorting]="true"
                    [externalPaging]="true"
                    [limit]="sortAndPage.limit"
                    [count]="sortAndPage.count"
                    [offset]="sortAndPage.currentPage"
                    [selectionType]="'single'"
                    [messages]="{ emptyMessage: 'Ni podatkov za prikaz.', totalMessage: 'skupaj' }"
                    (page)="onPage($event)"
                    (sort)="onSort($event)"
                    (select)="editWorkGroup($event)"
                    class="bootstrap"
                >
                    <ngx-datatable-column [flexGrow]="2" name="IME DELOVNE SKUPINE" prop="name">
                        <ng-template ngx-datatable-cell-template let-value="value">
                            {{ value }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [flexGrow]="1" name="ŠTEVILO VKLJUČENIH DELAVCEV" prop="employeesCount">
                        <ng-template ngx-datatable-cell-template let-value="value">
                            {{ value }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [flexGrow]="1" [sortable]="false" name="F8204" prop="">
                        <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                            <span *ngIf="hasTemplate(ReferralType.F8204, row); else notSet8204" container="body" ngbTooltip="Nastavljena predloga">
                                <i class="las la-check text-success"></i>
                            </span>
                            <ng-template #notSet8204>
                                <span container="body" ngbTooltip="Predloga ni nastavljena">
                                    <i class="las la-times text-danger"></i>
                                </span>
                            </ng-template>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column [flexGrow]="1" [sortable]="false" name="F8205" prop="">
                        <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                            <span *ngIf="hasTemplate(ReferralType.F8205, row); else notSet8205" container="body" ngbTooltip="Nastavljena predloga">
                                <i class="las la-check text-success"></i>
                            </span>
                            <ng-template #notSet8205>
                                <span container="body" ngbTooltip="Predloga ni nastavljena">
                                    <i class="las la-times text-danger"></i>
                                </span>
                            </ng-template>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </div>
        </div>
    </div>
</div>
