import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { HrefPreventDefaultDirective } from '../directives/href-prevent-default.directive';
import { InputRefDirective } from '../directives/input-ref.directive';
import { NoDblClickDirective } from '../directives/no-dbl-click.directive';
import { ComBadgeComponent } from './com-badge/com-badge.component';
import { ComBasePopupComponent } from './com-base-popup/com-base-popup.component';
import { ComPopupHostDirective } from './com-base-popup/com-popup-host.directive';
import { BusinessSubjectIconComponent } from './com-business-subject-icon/business-subject-icon.component';
import { ComFilterComponent } from './com-filter/com-filter.component';
import { ComInlineLoadingComponent } from './com-inline-loading/com-inline-loading.component';
import { ComLoadingComponent } from './com-loading/com-loading.component';
import { ComReservationStatusComponent } from './com-reservation-status/com-reservation-status.component';
import { ComViewReferralComponent } from './com-view-referral/com-view-referral.component';

@NgModule({
    imports: [CommonModule, RouterModule, NgbModule, FormsModule, ReactiveFormsModule, NgSelectModule, PdfViewerModule],
    declarations: [
        ComBasePopupComponent,
        ComPopupHostDirective,
        ComLoadingComponent,
        NoDblClickDirective,
        ComInlineLoadingComponent,
        ComFilterComponent,
        HrefPreventDefaultDirective,
        BusinessSubjectIconComponent,
        ComViewReferralComponent,
        InputRefDirective,
        ComReservationStatusComponent,
        ComBadgeComponent,
    ],
    exports: [
        NoDblClickDirective,
        ComInlineLoadingComponent,
        ComFilterComponent,
        HrefPreventDefaultDirective,
        BusinessSubjectIconComponent,
        ComViewReferralComponent,
        InputRefDirective,
        ComReservationStatusComponent,
        ComBadgeComponent,
        ComLoadingComponent,
    ],
})
export class ComponentsModule {}
