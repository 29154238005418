import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { MenuStateModel } from './menu-state.model';
import { MenuStateActions } from './menu.actions';

const MENUSTATE_TOKEN: StateToken<MenuStateModel> = new StateToken('menustate');
const DEFAULT_STATE: MenuStateModel = {
    headerInfo: undefined,
};

@State<MenuStateModel>({
    name: MENUSTATE_TOKEN,
    defaults: DEFAULT_STATE,
})
@Injectable()
export class MenuState {
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    @Selector([MENUSTATE_TOKEN])
    public static getHeaderInfo(state: MenuStateModel) {
        return state.headerInfo;
    }

    @Action(MenuStateActions.SetHeaderData)
    public SetHeaderData(ctx: StateContext<MenuStateModel>, { headerData }: MenuStateActions.SetHeaderData) {
        ctx.patchState({
            headerInfo: headerData,
        });
    }
    @Action(MenuStateActions.ClearHeaderData)
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line no-empty-pattern
    public ClearHeaderData(ctx: StateContext<MenuStateModel>, {}: MenuStateActions.ClearHeaderData) {
        ctx.patchState({
            headerInfo: undefined,
        });
    }
}
