<div *ngIf="workGroupForm">
    <!-- WORKGROUP FORM-->
    <div class="mt-4">
        <form [formGroup]="workGroupForm" class="pr-3 form">
            <!-- Top level data -->
            <div class="row justify-content-between mb-3">
                <div class="col-12 col-xl-6">
                    <div class="form-group m-form__group">
                        <label class="form-control-label text-white">POIMENOVANJE DELOVNE SKUPINE</label>
                        <input
                            type="text"
                            formControlName="name"
                            class="form-control m-input"
                            [ngClass]="{
                                'is-invalid': workGroupForm?.controls?.name.errors && isSubmitted
                            }"
                        />
                    </div>
                </div>
            </div>
            <!-- Referral selection nav -->
            <div>
                <ul class="nav nav-pills nav-justified">
                    <!-- @TODO: Ignored manually on 2023-10-13 -->
                    <!-- eslint-disable @angular-eslint/template/use-track-by-function -->
                    <li
                        *ngFor="let refType of referralTypes"
                        (click)="selectReferralType(refType)"
                        class="nav-item mf-pointer font-weight-bold"
                        [ngClass]="{ active: selectedReferral === refType }"
                    >
                        <!-- eslint-enable -->
                        <a class="nav-link">{{ refType | referralString: 'short' }}</a>
                    </li>
                </ul>
            </div>
            <div class="mf-content">
                <!-- Template data -->
                <ng-container *ngIf="selectedReferral; else refNotSelected">
                    <!-- row1 -->
                    <div class="row pt-4">
                        <div class="col-12 col-xl-8">
                            <div class="form-group m-form__group">
                                <com-textarea
                                    [text]="'POMEMBNI PODATKI IZ OCENE TVEGANJA'"
                                    [rows]="4"
                                    [formElement]="formControls['riskAssessment']"
                                    [isSubmitted]="isSubmitted"
                                    [controlName]="'riskAssessment'"
                                    [ngClass]="{
                                        'is-invalid': formControls?.riskAssessment.errors && isSubmitted
                                    }"
                                ></com-textarea>
                            </div>
                        </div>
                        <div formGroupName="template" class="col-12 col-xl-4">
                            <div class="form-group m-form__group">
                                <label class="form-control-label text-uppercase font-weight-bold">DATUM OCENE TVEGANJA</label>
                                <input
                                    #d3="ngbDatepicker"
                                    [readOnly]="true"
                                    [maxDate]="{
                                        year: today.getFullYear(),
                                        month: today.getMonth() + 1,
                                        day: today.getDate()
                                    }"
                                    (click)="d3.toggle()"
                                    type="text"
                                    id="13"
                                    formControlName="riskAssessmentDate"
                                    size="9"
                                    ngbDatepicker
                                    autocomplete="off"
                                    class="form-control"
                                />
                            </div>
                        </div>
                    </div>
                    <!-- row2-->
                    <div class="row">
                        <div class="col-12 col-xl-12">
                            <div class="form-group m-form__group">
                                <com-textarea
                                    [text]="'DELOVNA OPREMA'"
                                    [controlName]="'workEquipment'"
                                    [rows]="2"
                                    [formElement]="formControls['workEquipment']"
                                    [isSubmitted]="isSubmitted"
                                    [ngClass]="{
                                        'is-invalid': formControls?.workEquipment.errors && isSubmitted
                                    }"
                                ></com-textarea>
                            </div>
                        </div>
                    </div>
                    <!-- row3-->
                    <div class="row">
                        <div class="col-12 col-xl-12">
                            <div class="form-group m-form__group">
                                <com-textarea
                                    [text]="'PREDMETI DELA'"
                                    [controlName]="'workplaceAssets'"
                                    [rows]="2"
                                    [formElement]="formControls['workplaceAssets']"
                                    [isSubmitted]="isSubmitted"
                                    [ngClass]="{
                                        'is-invalid': formControls?.workplaceAssets.errors && isSubmitted
                                    }"
                                ></com-textarea>
                            </div>
                        </div>
                    </div>
                    <!-- row4-->
                    <div class="row">
                        <div class="col-12 col-xl-12">
                            <div class="form-group m-form__group">
                                <com-textarea
                                    [text]="'IZPOSTAVLJENOST TVEGANJEM'"
                                    [rows]="3"
                                    [formElement]="formControls['riskExposure']"
                                    [isSubmitted]="isSubmitted"
                                    [controlName]="'riskExposure'"
                                    [ngClass]="{
                                        'is-invalid': formControls?.riskExposure.errors && isSubmitted
                                    }"
                                ></com-textarea>
                            </div>
                        </div>
                    </div>
                    <!-- row5-->
                    <div class="row">
                        <div class="col-12 col-xl-12">
                            <div class="form-group m-form__group">
                                <com-textarea
                                    [text]="'PO ZADNJI OCENI TVEGANJA SO BILI IZVEDENI NASLEDNJI UKREPI NA PODROČJU TEHNIČNEGA VARSTVA'"
                                    [rows]="3"
                                    [formElement]="formControls['measurementsAfterRiskAssessment']"
                                    [isSubmitted]="isSubmitted"
                                    [controlName]="'measurementsAfterRiskAssessment'"
                                    [ngClass]="{
                                        'is-invalid': formControls?.measurementsAfterRiskAssessment.errors && isSubmitted
                                    }"
                                ></com-textarea>
                            </div>
                        </div>
                    </div>
                    <!-- row6-->
                    <div class="row">
                        <div class="col-12 col-xl-12">
                            <div class="form-group m-form__group">
                                <com-textarea
                                    [text]="'OSEBNA VAROVALNA OPREMA'"
                                    [rows]="3"
                                    [formElement]="formControls['personalProtectiveGear']"
                                    [isSubmitted]="isSubmitted"
                                    [controlName]="'personalProtectiveGear'"
                                    [ngClass]="{
                                        'is-invalid': formControls?.personalProtectiveGear.errors && isSubmitted
                                    }"
                                ></com-textarea>
                            </div>
                        </div>
                    </div>

                    <!-- row7-->
                    <div class="row">
                        <div class="col-12 col-xl-12">
                            <div class="form-group m-form__group">
                                <com-textarea
                                    [text]="'POSEBNE ZDRAVSTVENE ZAHTEVE, KI JIH MORA IZPOLNJEVATI DELAVEC'"
                                    [rows]="3"
                                    [formElement]="formControls['specialHealthRequirements']"
                                    [isSubmitted]="isSubmitted"
                                    [controlName]="'specialHealthRequirements'"
                                    [ngClass]="{
                                        'is-invalid': formControls?.specialHealthRequirements.errors && isSubmitted
                                    }"
                                ></com-textarea>
                            </div>
                        </div>
                    </div>

                    <!-- row8-->
                    <div class="row">
                        <div class="col-12 col-xl-12">
                            <div class="form-group m-form__group">
                                <com-textarea
                                    [text]="'DELOVNO MESTO JE NEUSTREZNO ZA'"
                                    [rows]="3"
                                    [formElement]="formControls['workplaceInappropriateFor']"
                                    [isSubmitted]="isSubmitted"
                                    [controlName]="'workplaceInappropriateFor'"
                                    [ngClass]="{
                                        'is-invalid': formControls?.workplaceInappropriateFor.errors && isSubmitted
                                    }"
                                ></com-textarea>
                            </div>
                        </div>
                    </div>
                    <!-- row9-->
                    <div class="row">
                        <div class="col-12 col-xl-12">
                            <div class="form-group m-form__group">
                                <com-textarea
                                    [text]="'PRIPOMBE DELODAJALCA'"
                                    [rows]="3"
                                    [formElement]="formControls['employerComments']"
                                    [isSubmitted]="isSubmitted"
                                    [controlName]="'employerComments'"
                                    [ngClass]="{
                                        'is-invalid': formControls?.employerComments.errors && isSubmitted
                                    }"
                                ></com-textarea>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-template #refNotSelected>
                    <div class="pt-4">
                        <b>IZBERITE TIP PREGLEDA.</b>
                    </div>
                </ng-template>
            </div>
        </form>
        <div *ngIf="!workGroupForm?.pristine || !workGroupForm?.controls?.template?.pristine" class="row pl-2 form__footer">
            <div class="col-11 col-xl-12">
                <div class="form-group m-form__group mt-3 mb-2 d-flex justify-content-between">
                    <button
                        [attr.data-testid]="'btn-save-workgroup-definition'"
                        (click)="submitWorkingGroupData()"
                        ngbTooltip="Shrani predlogo za izbrani tip napotnice"
                        class="btn btn-primary"
                    >
                        <span>SHRANI</span>
                    </button>
                    <button
                        [attr.data-testid]="'btn-save-workgroup-definition-all'"
                        (click)="submitWorkingGroupData(true)"
                        ngbTooltip="Shrani predlogo za vse tipe napotnic"
                        class="btn btn-outline-primary"
                    >
                        <span>SHRANI ZA VSE</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
