import { BusinessSubject } from '../../model/business-subject';
import { Employer } from '../../model/employer';
import { TokenData } from './../../../_modules/public/_model/token-data';
// @TODO: Ignored with eslint-interactive on 2023-09-15
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Booking } from './../../model/booking';
import { BsGuiSettings } from './../../model/bs-gui-settings';
import { Contractor } from './../../model/contractor.model';
import { Module } from './../../model/module.model';
import { Referral } from './../../model/referral';
import { User } from './../../model/user';

// @TODO: Ignored with eslint-interactive on 2023-09-15
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace BaseStateActions {
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace Common {
        export class SetTokenData {
            public static readonly type = '[common] SetTokenData';
            constructor(public readonly tokenData: TokenData) {}
        }
        export class ClearTokenData {
            public static readonly type = '[common] ClearTokenData';
            // @TODO: Ignored with eslint-interactive on 2023-09-15
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            constructor() {}
        }

        export class SetUser {
            public static readonly type = '[common] SetUser';
            constructor(public readonly user: User) {}
        }

        export class SetBsGuiSettings {
            public static readonly type = '[common] SetBsGuiSettings';
            constructor(public readonly settings: BsGuiSettings) {}
        }

        export class ClearBaseState {
            public static readonly type = '[common] ClearBaseState';
        }

        export class SetContractors {
            public static readonly type = '[common] SetContractors';
            constructor(public readonly contractors: Contractor[]) {}
        }

        export class SetBsAdditionalInfo {
            public static readonly type = '[common] SetBsAdditionalInfo';
            constructor(public readonly additionalInfo: Employer) {}
        }
        export class SetBSSendingRules {
            public static readonly type = '[common] SetBSSendingRules';
            // @TODO: Ignored with eslint-interactive on 2023-09-15
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            constructor(public readonly bsSendingRules: { notificationRules: any[] }) {}
        }
        export class SetActiveBS {
            public static readonly type = '[common] SetActiveBS';
            constructor(public readonly activeBs: BusinessSubject) {}
        }
        export class SetEmployerId {
            public static readonly type = '[common] SetEmployerId';
            constructor(public readonly employerId: string) {}
        }
    }

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace Booking {
        export class SetBooking {
            public static readonly type = '[booking] SetBooking';
            constructor(public readonly booking: Booking) {}
        }
        export class RemoveBooking {
            public static readonly type = '[booking] RemoveBooking';
            constructor(public readonly guid: string) {}
        }

        export class SetReferral {
            public static readonly type = '[booking] SetReferral';
            constructor(
                public readonly referral: Referral,
                public readonly guid: string,
            ) {}
        }
    }

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace Bs {
        export class SetBsModules {
            public static readonly type = '[Bs] SetBsModules';
            constructor(public readonly modules: Module[]) {}
        }
    }
}
