import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { NotificationEnum } from '../enums/notification.enum';
import { NotificationElement } from '../model/notificationElement';
import { ReservationDetailsTabType } from './../../_modules/reservations/enum/resDetailsTabsType.enum';
import { ApiUrlService } from './api-url.service';
import { HttpClientService } from './http-client.service';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    constructor(
        private httpClient: HttpClientService,
        private helper: ApiUrlService,
    ) {}

    public getNotifications(bsid: number, from: string, to: string): Observable<NotificationElement[]> {
        // TODO se doda nov tip notifikacij za PREVENTIVNI, ko delavcu poteče PERIODIKA
        return this.httpClient.get(this.helper.getAssistanceUrl(2) + `bs/${bsid}/notifications?from=${from}&to=${to}`);
    }

    public markNotificationAsSeen(bsid: number, notificationId: number) {
        return this.httpClient.put(this.helper.getAssistanceUrl(2) + `bs/${bsid}/notifications/${notificationId}`);
    }
    public markAllNotificationsAsSeen(bsid: number) {
        return this.httpClient.put(this.helper.getAssistanceUrl(2) + `bs/${bsid}/notifications`);
    }
    public prepareNotifications(notifications: NotificationElement[]): NotificationElement[] {
        const supportedNotifTypes: NotificationEnum[] = [
            NotificationEnum.RESERVATION_CANCELED,
            NotificationEnum.CASE_CANCELED,
            NotificationEnum.ASISTANT_CASE_CONTRACTOR_COMMENT_ADDED,
            NotificationEnum.ASISTANT_CASE_CONTRACTOR_DOCUMENT_ADDED,
        ];
        return <NotificationElement[]>notifications
            // @TODO: Ignored with eslint-interactive on 2023-09-15
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-extra-non-null-assertion
            ?.filter(notif => supportedNotifTypes.includes(notif?.type!!))
            .map((notification: NotificationElement) => {
                switch (notification?.type) {
                    case NotificationEnum.RESERVATION_CANCELED:
                    case NotificationEnum.CASE_CANCELED:
                        _.set(notification, 'data.msg', 'Termin preklican');
                        return notification;
                    case NotificationEnum.ASISTANT_CASE_CONTRACTOR_COMMENT_ADDED:
                        _.set(notification, 'data.msg', 'Prejeto novo sporočilo izvajalca');
                        _.set(notification, 'tab', ReservationDetailsTabType.COMMUNICATION);
                        return notification;
                    case NotificationEnum.ASISTANT_CASE_CONTRACTOR_DOCUMENT_ADDED:
                        _.set(notification, 'data.msg', 'Prejeto zdravniško spričevalo');
                        _.set(notification, 'tab', ReservationDetailsTabType.REPORT);
                        return notification;
                }
            });
    }
}
