import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngxs/store';
import { AppModulesType } from 'src/app/_common/enums/appModulesType.enum';
import { WorkGroupData } from 'src/app/_common/model/work-group-data';
import { ActiveModulesService } from 'src/app/_common/services/active-modules.service';
import { BaseState } from 'src/app/_common/state/base/base.state';
import { TextareaRichTitle } from './model/textarea-rich-title';

@Component({
    selector: 'com-textarea',
    templateUrl: './com-textarea.component.html',
    styleUrls: ['./com-textarea.component.scss'],
})
export class ComTextareaComponent implements OnInit {
    @Input() text: string;

    @Input() richTitle: TextareaRichTitle;

    @Input() formElement: UntypedFormControl;

    @Input() isSubmitted: boolean;

    //Inputs for workGroups
    @Input() connectWithWorkingGroups = false;

    @Input() rows: number;

    @Input() controlName:
        | 'autoFill'
        | 'workingProcessDescription'
        | 'workplaceToolset'
        | 'workplaceObjects'
        | 'workplaceRisks'
        | 'workplaceLastImprovements'
        | 'personalProtectiveGear'
        | 'specialHealthRequirements'
        | 'workplaceInappropriateFor'
        | 'employerComments';

    constructor(
        private store: Store,
        private activeModules: ActiveModulesService,
    ) {}
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public workGroupData: WorkGroupData | any;
    public rbid: number = Math.floor(Math.random() * 1000);

    get height() {
        const initialHeight = 75;
        if (this.rows) {
            //defined rows -> lock height
            return this.rows * 31;
        } else {
            //rows not defined -> height of filled rows
            // @TODO: Ignored with eslint-interactive on 2023-09-15
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return initialHeight + this.formElement?.value?.split('').filter((el: any) => el === '\n')?.length * 25;
        }
    }
    ngOnInit() {
        if (this.connectWithWorkingGroups && this.activeModules.isAM(AppModulesType.WORK_GROUPS)) {
            this.store.select(BaseState.GetWorkGroupsData).subscribe(workGroupData => {
                if (workGroupData !== undefined) {
                    this.workGroupData = workGroupData;
                    if (this.workGroupData?.autoFill) this.fill();
                }
            });
        }
    }
    fill() {
        this.formElement.patchValue(this.workGroupData[this.controlName]);
    }
}
