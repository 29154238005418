import { HttpEventType } from '@angular/common/http';
import { Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { DocumentResponse } from '../../model/documentResponse.model';
import { MedaViewService } from '../../modules/meda-view/meda-view.service';
import { DocumentsService } from '../../services/documents.service';
import { BaseState } from '../../state/base/base.state';

@Component({
    selector: 'com-view-referral',
    templateUrl: './com-view-referral.component.html',
    styleUrls: ['./com-view-referral.component.scss'],
})
export class ComViewReferralComponent implements OnDestroy {
    private referralId: string;
    @Input() pdfContainerHeight?: number = (window?.innerHeight || 850) + 50;
    @Input() bgPanel = false;
    @Input() showBorder = true;
    @Input() set setReferralId(referralId: string) {
        if (referralId) {
            this.isLoading = true;
            this.referralId = referralId;
            this.loadReferral(this.referralId);
        }
    }
    @Input() set reload(val: unknown) {
        if (this.referralId) {
            this.isLoading = true;
            this.loadReferral(this.referralId);
        }
    }

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() set setBlob(value: any) {
        if (value) {
            this.isLoading = true;
            this.documentFile = URL.createObjectURL(new Blob([value], { type: 'application/pdf' }));
        }
    }

    @Input() set setDocumentId(value: string) {
        if (value) {
            this.isLoading = true;
            this.loadReferralFromDocument(value);
        }
    }

    @Input() set setObjectURLFile(value: Blob) {
        if (value) {
            this.isLoading = true;
            this.documentFile = value;
        }
    }

    @Input() set setObjectFile(value: Blob) {
        if (value) {
            this.documentFileForMedaView = value;
        }
    }

    private onDestroy$ = new Subject<void>();
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public documentFile: any;
    public documentFileForMedaView: any;

    public isLoading = false;
    private bsId = this.store.selectSnapshot(BaseState.getBSId);

    constructor(
        private documentApi: DocumentsService,
        private store: Store,
        private mediaView: MedaViewService,
    ) {}

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    private loadReferralFromDocument(documentId: string): void {
        this.documentApi
            .openDocument(this.bsId, documentId)
            .pipe(
                takeUntil(this.onDestroy$),
                filter(event => event.type === HttpEventType.Response),
                map((res: DocumentResponse) => {
                    return { document: new Blob([res.body], { type: res.body.type }) };
                }),
            )
            // @TODO: Ignored with eslint-interactive on 2023-09-15
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .subscribe((docReq: any) => {
                this.documentFile = URL.createObjectURL(new Blob([docReq.document], { type: 'application/pdf' }));
                this.documentFileForMedaView = docReq;
            });
    }

    private loadReferral(referralId: string): void {
        this.documentApi
            .getReferralDocument(referralId)
            .pipe(
                takeUntil(this.onDestroy$),
                filter(event => event.type === HttpEventType.Response),
                map((res: DocumentResponse) => {
                    return { document: new Blob([res.body], { type: res.body.type }) };
                }),
            )
            .subscribe(docReq => {
                this.documentFile = URL.createObjectURL(new Blob([docReq.document], { type: 'application/pdf' }));
                this.documentFileForMedaView = docReq;
            });
    }

    public onAfterLoad(event: { _pdfInfo: { numPages: number } }) {
        this.isLoading = false;
    }

    public openDocument(): void {
        this.documentFileForMedaView = { document: new Blob([this.documentFileForMedaView.document], { type: 'application/pdf' }) };
        this.mediaView.openDocumentViewer(this.documentFileForMedaView, 'obrazec.pdf', false, true);
    }
}
