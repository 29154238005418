import { ViewportScroller } from '@angular/common';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
// @TODO: Ignored with eslint-interactive on 2023-09-15
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import { EMPTY, forkJoin, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Comment } from '../../../../_common/model/comment';
import { ResDocument } from '../../model/res-document';
import { ReservationDetails } from '../../model/reservation-details';
import { ReservationsService } from '../../reservations.service';
import { MedaViewService } from './../../../../_common/modules/meda-view/meda-view.service';
import { OpenDocumentRequest } from './../../../../_common/modules/meda-view/model/open-document-request';
import { DocumentsService } from './../../../../_common/services/documents.service';
import { LoaderService } from './../../../../_common/services/loader.service';
import { MfToasterService } from './../../../../_common/services/mf-toaster.service';
import { BaseState } from './../../../../_common/state/base/base.state';
// @TODO: Ignored with eslint-interactive on 2023-09-15
// @TODO: Ignored with eslint-interactive on 2023-09-15
// @TODO: Ignored with eslint-interactive on 2023-09-15
// @TODO: Ignored with eslint-interactive on 2023-09-15
// @TODO: Ignored with eslint-interactive on 2023-09-15

@Component({
    selector: 'res-reservation-comm',
    templateUrl: './res-reservation-comm.component.html',
    styleUrls: ['./res-reservation-comm.component.scss'],
})
export class ResReservationCommComponent implements OnInit, OnDestroy {
    constructor(
        private fb: UntypedFormBuilder,
        private resService: ReservationsService,
        private store: Store,
        private activeRoute: ActivatedRoute,
        private toast: MfToasterService,
        private documentService: DocumentsService,
        private mediaView: MedaViewService,
        private loader: LoaderService,
        private scroller: ViewportScroller,
    ) {}

    public communicationForm: UntypedFormGroup;
    public attachments: (Comment | ResDocument)[];
    private bsId = this.store.selectSnapshot(BaseState.getBSId);
    public isFormSubmitted = false;
    public onDestroy$ = new Subject<void>();
    private filesControl = new UntypedFormControl(null, FileUploadValidators.filesLimit(1));
    public initFetchCompleted = false;

    @Input() reservationDetails: ReservationDetails;

    ngOnInit(): void {
        this.createForm();
        this.getAttachments();
    }

    ngOnDestroy() {
        this.onDestroy$.next();
    }

    get formData() {
        return this.communicationForm.controls;
    }

    public createForm(): void {
        this.communicationForm = this.fb.group({
            message: this.fb.control({ value: '', disabled: false }, Validators.required),
            uploadFile: this.filesControl,
        });
    }

    public getAttachments() {
        forkJoin({
            preventivniComments: this.resService.getCaseComments(this.bsId, this.reservationDetails.caseId),
            prevDocu: this.resService.getCaseDocuments(this.bsId, this.reservationDetails.caseId, true),
            hisComments: this.resService.getCaseContractorComments(this.bsId, this.reservationDetails.caseId),
            hisDocu: this.resService.getCaseContractorDocuments(this.bsId, this.reservationDetails.caseId, true),
        })
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
                attachments => {
                    // @TODO: Ignored with eslint-interactive on 2023-09-15
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    this.attachments = _.sortBy<(Comment | ResDocument)[] | any[]>(
                        [...attachments.hisComments, ...attachments.preventivniComments, ...attachments.prevDocu, ...attachments.hisDocu],
                        'dateAdded',
                    );
                    this.initFetchCompleted = true;
                    setTimeout(() => {
                        this.scroller.scrollToAnchor('commentInput');
                    }, 0);
                },
                () => (this.initFetchCompleted = true),
            );
    }

    public uploadFile(files: FileList) {
        let fileUpload$: Observable<null | string> = of(null);

        if (files && files.length > 0) {
            // file upload
            fileUpload$ = this.documentService.uploadFile(files[0], files[0]?.name, this.bsId).pipe(
                switchMap(event => {
                    // @TODO: Ignored with eslint-interactive on 2023-09-15
                    // eslint-disable-next-line no-empty
                    if (event.type === HttpEventType.UploadProgress) {
                    } else if (event instanceof HttpResponse) {
                        console.log('File is completely loaded!');
                    }
                    if (event.type === HttpEventType.Response) {
                        return of(_.get(event, 'body'));
                    }
                    return EMPTY;
                }),
                tap({
                    error: err => {
                        const parsedErrorMSG = JSON.parse(err?.error || '{}')?.errors[0]?.msg;
                        switch (parsedErrorMSG) {
                            case 'Invalid file format':
                                this.toast.errorAndSend('Nedovoljen tip datoteke', err);
                                break;
                            default:
                                this.toast.errorAndSend('Napaka pri oddaji datoteke', err);
                        }
                    },
                }),
            );
        }
        return fileUpload$;
    }

    public saveCommunication(data: { fileId: string | null; message: string | null }) {
        const documents = [];
        const comments = [];

        if (data.fileId) {
            documents.push(data.fileId);
        }

        if (data.message) {
            comments.push({ comment: data.message });
        }

        return this.resService.addCaseCommunication(this.bsId, this.reservationDetails.caseId, comments, documents);
    }

    public onSubmitForm(): void {
        this.isFormSubmitted = true;

        of(this.communicationForm.value)
            .pipe(
                takeUntil(this.onDestroy$),
                switchMap(formValues =>
                    this.uploadFile(_.get(formValues, 'uploadFile', [])).pipe(
                        map(fileId => {
                            return {
                                message: formValues.message,
                                fileId,
                            };
                        }),
                    ),
                ),
                switchMap(response => this.saveCommunication(response)),
            )
            .subscribe({
                next: response => {
                    this.isFormSubmitted = false;
                    this.getAttachments();

                    if (_.get(response, 'documentAggregateIds', []).length > 0) {
                        this.filesControl.setValue([]);
                        this.toast.success('Datoteka uspešno oddana.');
                    }

                    if (_.get(response, 'commentAggregateIds', []).length > 0) {
                        this.communicationForm.controls['message'].reset();
                        this.toast.success('Komentar uspešno oddan.');
                    }
                },
                error: err => this.toast.errorAndSend('Napaka pri oddaji komentarja', err),
            });
    }

    public downloadDocument(doc: { value: ResDocument }): void {
        this.loader.setLoading(true);
        this.documentService
            .getDocument(this.bsId, doc?.value?.documentId)
            .pipe(
                takeUntil(this.onDestroy$),
                filter(event => event.type === HttpEventType.Response),
                // @TODO: Ignored with eslint-interactive on 2023-09-15
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                map((res: any) => {
                    return { document: new Blob([res.body], { type: res.body.type }) };
                }),
            )
            .subscribe(
                (res: OpenDocumentRequest) => {
                    this.loader.setLoading(false);
                    if (res) {
                        this.mediaView.openDocumentViewer(res, doc?.value?.description);
                    }
                },
                () => this.loader.setLoading(false),
            );
    }
}
