import { OverlayModule } from '@angular/cdk/overlay';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeSlExtra from '@angular/common/locales/extra/sl';
import localeSl from '@angular/common/locales/sl';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppBasicsModule, AppBasicsModuleConfigModel } from '@meddev/fe-shared';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { IntercomModule } from 'ng-intercom';
import { NgxPendoModule } from 'ngx-pendo';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';
import { environment } from './../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import { CustomAdapter, CustomDateParserFormatter } from './_common/datepicker-adapter';
import { AuthGuard } from './_common/guards/auth.guard';
import { TokenInterceptorService } from './_common/guards/token.interceptor.service';
import { EnvironmentHelper } from './_common/helpers/environment-helper';
import { MedaViewModule } from './_common/modules/meda-view/meda-view.module';
import { BaseState } from './_common/state/base/base.state';
import { MenuState } from './_common/state/menu/menu.state';
import { WorkersModule } from './_modules/workers/workers.module';
import { WorkingGroupsModule } from './_modules/working-groups/working-groups.module';

registerLocaleData(localeSl, 'sl', localeSlExtra);
EnvironmentHelper.initEnvironment();

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
};
// import * as PDFJS from 'pdfjs-dist';
// PDFJS.GlobalWorkerOptions.workerSrc = 'pdf.worker.js';
@NgModule({
    declarations: [AppComponent],
    imports: [
        GraphQLModule,
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        OverlayModule,
        NgxsModule.forRoot([BaseState, MenuState], {
            developmentMode: !environment?.production,
        }),
        NgxsStoragePluginModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        NgxsLoggerPluginModule.forRoot({ disabled: true }),
        BrowserAnimationsModule,
        // PipesModule,
        MedaViewModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-center',
        }),
        IntercomModule.forRoot({
            appId: environment.INTERCOM_KEY,
            updateOnRouterChange: true,
        }),
        NgxPermissionsModule.forRoot(),
        WorkersModule,
        WorkingGroupsModule,
        PerfectScrollbarModule,
        // CustomFormsModule,
        NgxPendoModule.forRoot({
            pendoApiKey: 'bc2a2925-a426-45ac-4dfb-6972378b56b8',
            // @TODO: Ignored with eslint-interactive on 2023-09-15
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            pendoIdFormatter: (value: any) => value.toString().toLowerCase(),
        }),
        AppBasicsModule.forRoot({
            version: environment.VERSION,
            production: environment.production,
            releaseStage: environment.RELEASE_STAGE as AppBasicsModuleConfigModel['releaseStage'],
            bugSnag: {
                apiKey: 'd13767d6b7050198b956417e6dcd6f4a',
            },
            matomo: {
                siteId: 2,
                dimensions: {
                    version: 3,
                    businessSubjectId: 2,
                },
            },
        }),
    ],
    providers: [
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true,
        },
        { provide: LOCALE_ID, useValue: 'sl' },
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
