import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SortAndPage } from '../../_common/model/sort-and-page';
import { WorkplaceDefinition, WorkplaceDefinitionResponse, WorkplaceDefinitionsResponse } from '../../_common/model/work-groups-template';
import {
    CREATE_WORKPLACE_DEFINITION,
    DELETE_WORKPLACE_DEFINITION,
    GET_WORKPLACE_DEFINITION,
    GET_WORKPLACE_DEFINITIONS,
    UPDATE_WORKPLACE_DEFINITION,
} from './working-groups.graphql';

@Injectable({
    providedIn: 'root',
})
export class WorkingGroupsService {
    constructor(private apollo: Apollo) {}

    public getWorkplaceDefinitions(
        employerId: string,
        { order = 'created_at', orderDirection = 'DESC', limit = 100, offset = 0 }: SortAndPage,
        name?: string,
        forceUpdate: boolean = false,
    ): Observable<WorkplaceDefinitionsResponse> {
        if (name === '') {
            name = undefined;
        }
        return this.apollo
            .query({
                query: GET_WORKPLACE_DEFINITIONS,
                variables: { employerId, order, orderDirection, limit, offset, name },
                fetchPolicy: forceUpdate ? 'no-cache' : 'cache-first',
            })
            .pipe(
                map(({ data: { workplaceDefinitions }, errors }) => {
                    if (errors) {
                        throw errors;
                    }
                    return workplaceDefinitions;
                }),
            );
    }

    public getWorkplaceDefinition(id: string, forceFetch: boolean = false): Observable<WorkplaceDefinition> {
        return this.apollo
            .query({
                query: GET_WORKPLACE_DEFINITION,
                variables: { id },
                fetchPolicy: forceFetch ? 'no-cache' : 'cache-first',
            })
            .pipe(
                map(({ data, errors }) => {
                    if (errors) {
                        throw errors;
                    }
                    return data.workplaceDefinition;
                }),
            );
    }

    public createWorkplace(workplaceDetails: WorkplaceDefinition): Observable<WorkplaceDefinitionResponse> {
        return this.apollo
            .mutate({
                mutation: CREATE_WORKPLACE_DEFINITION,
                variables: { workplaceDetails },
            })
            .pipe(
                map(({ data, errors }) => {
                    if (errors) {
                        throw errors;
                    }
                    if (!data?.createFullWorkplace) {
                        throw new Error('Error creating workplace');
                    }
                    return data?.createFullWorkplace;
                }),
            );
    }

    public updateWorkplaceDefinition(workplaceDetails: WorkplaceDefinition): Observable<WorkplaceDefinitionResponse> {
        return this.apollo
            .mutate({
                mutation: UPDATE_WORKPLACE_DEFINITION,
                variables: { workplaceDetails },
            })
            .pipe(
                map(({ data, errors }) => {
                    if (errors) {
                        throw errors;
                    }
                    if (!data?.updateFullWorkplace) {
                        throw new Error('Error updating workplace');
                    }
                    return data?.updateFullWorkplace;
                }),
            );
    }

    public deleteWorkplaceDefinition(id: string): Observable<void> {
        return this.apollo
            .mutate({
                mutation: DELETE_WORKPLACE_DEFINITION,
                variables: { id },
            })
            .pipe(
                map(({ errors }) => {
                    if (errors) {
                        throw errors;
                    }
                }),
            );
    }
}
