<div class="form-group">
    <ng-container *ngTemplateOutlet="richTitleTemplate"></ng-container>
    <div style="position: relative">
        <textarea
            [id]="rbid"
            [formControl]="formElement"
            [style.height.px]="height"
            [style.resize]="rows ? 'none' : 'auto'"
            type="text"
            class="form-control"
            [ngClass]="{
                'border-danger': formElement?.errors && (formElement?.dirty || formElement?.touched || isSubmitted)
            }"
        ></textarea>
        <button
            *ngIf="workGroupData && connectWithWorkingGroups && !workGroupData?.autoFill"
            (click)="fill()"
            ngbTooltip="Samoizpolni"
            placement="top"
            class="input__fill"
        >
            <i class="las la-spell-check"></i>
        </button>
    </div>
</div>

<ng-template #richTitleTemplate>
    <label *ngIf="richTitle?.title?.length < 1" [for]="rbid" class="col-form-label pr-2 font-weight-bold">{{ text }}</label>
    <ng-container *ngIf="richTitle?.title?.length >= 1">
        <label [for]="rbid" class="col-form-label pr-1 font-weight-bold">{{ richTitle.title }}</label>
        <span *ngIf="richTitle?.parentheticalSubtitle">({{ richTitle.parentheticalSubtitle }}){{ richTitle?.addColon ? ':' : '' }}</span>
    </ng-container>
</ng-template>
