import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AppBasicsService } from '@meddev/fe-shared';
import { Store } from '@ngxs/store';
import { LoaderService } from './_common/services/loader.service';
import { RulesService } from './_common/services/rules.service';
import { BaseState } from './_common/state/base/base.state';
import { MenuStateActions } from './_common/state/menu/menu.actions';
declare global {
    interface Window {
        // @TODO: Ignored with eslint-interactive on 2023-09-15
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dataLayer: any[];
    }
}
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(
        private rules: RulesService,
        private store: Store,
        public router: Router,
        private loader: LoaderService,
        private appBasics: AppBasicsService,
    ) {
        this.router.events?.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.loader.setLoading(true);
            }
            if (event instanceof NavigationEnd) {
                if (!event.urlAfterRedirects?.includes('login')) {
                    const bs = this.store.selectSnapshot(BaseState.getBS);
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        bsId: bs?.id,
                    });
                }
                this.loader.setLoading(false);
            }
        });

        const bs = this.store.selectSnapshot(BaseState.getBS);

        if (bs.id) {
            this.appBasics.setBusinessSubject(bs);
        }
        const user = this.store.selectSnapshot(BaseState.getUser);
        if (user) {
            this.appBasics.setUser(user);
        }
    }
    title = 'preventivni';
    ngOnInit() {
        this.rules.setRules();
        this.store.dispatch(new MenuStateActions.ClearHeaderData());
    }
}
