export enum CaseStatus {
    NO_RESERVATIONS = 'NO_RESERVATIONS',
    WAITING_QUEUE = 'WAITING_QUEUE',
    WQ_ITEM_CREATED = 'WQ_ITEM_CREATED',
    PARTIALLY_OK = 'PARTIALLY_OK',
    RESERVATION_CANCELED = 'RESERVATION_CANCELED',
    RESERVATION_CREATED = 'RESERVATION_CREATED',
    CASE_CONCLUDED = 'CASE_CONCLUDED',
    PARTIALLY_NOT_OK = 'PARTIALLY_NOT_OK',
    VISIT_CONCLUDED = 'VISIT_CONCLUDED',
}
