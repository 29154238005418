import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from './../../../_common/model/menu-item';

@Component({
    selector: 'lay-sub-menu',
    templateUrl: './lay-sub-menu.component.html',
    styleUrls: ['./lay-sub-menu.component.scss'],
})
export class LaySubMenuComponent implements OnInit {
    @Input()
    sumbMenu: MenuItem[] = [];

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit() {}
}
