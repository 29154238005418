import { Location } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SelectionType } from '@swimlane/ngx-datatable';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { BaseState } from 'src/app/_common/state/base/base.state';
import { MenuStateActions } from 'src/app/_common/state/menu/menu.actions';
import { Employee } from '../../_common/model/employee';
import { LoaderService } from '../../_common/services/loader.service';
import { MfToasterService } from '../../_common/services/mf-toaster.service';
import { NavigateService } from '../../_common/services/navigate.service';
import { WorkerDetailsComponent } from './components/worker-details/worker-details.component';
import { WorkerDetailsTabType } from './enums/workerDetailsTabsType.enum';
import { WorkersService } from './workers.service';

@Component({
    selector: 'app-workers',
    templateUrl: './workers.component.html',
    styleUrls: ['./workers.component.scss'],
})
export class WorkersComponent implements OnInit, OnDestroy {
    constructor(
        private store: Store,
        private fb: UntypedFormBuilder,
        private navigate: NavigateService,
        private loader: LoaderService,
        private activeRoute: ActivatedRoute,
        private router: Router,
        private workerService: WorkersService,
        private toast: MfToasterService,
        private location: Location,
    ) {}

    protected readonly SelectionType = SelectionType;
    public allWorkers: Employee[] = [];
    public filterForm: UntypedFormGroup;
    private employerId = this.store.selectSnapshot(BaseState.getEmployerId);
    private onDestroy$ = new Subject<void>();
    public columnsArray = [
        { prop: 'lastName', fieldName: 'last_name', name: 'PRIIMEK' },
        { prop: 'firstName', fieldName: 'first_name', name: 'IME' },
        { prop: 'emso', fieldName: 'emso', name: 'EMŠO' },
        { prop: 'address', fieldName: 'address', name: 'NASLOV' },
        { prop: 'city', fieldName: 'city', name: 'POŠTA' },
        { prop: 'profession', fieldName: 'profession', name: 'POKLIC' },
    ];

    public sortAndPage = {
        order: 'created_at',
        orderDirection: 'desc',
        count: 0,
        offset: 0,
        limit: Math.floor((window.innerHeight - 380) / 50),
        currentPage: 0,
    };

    @HostListener('window:resize', ['$event'])
    onResize() {
        if (window.innerWidth > 0) {
            this.sortAndPage.limit = Math.floor((window.innerHeight - 380) / 50);
            this.filterWorkers(true);
        }
    }

    ngOnInit(): void {
        this.store.dispatch(
            new MenuStateActions.SetHeaderData({
                iconClass: 'las la-address-book',
                title: 'Seznam delavcev',
                description: 'seznam delavcev vašega podjetja z zgodovino preventivnih pregledov',
            }),
        );
        this.onResize();
        this.initFilterForm();

        // @TODO: Ignored with eslint-interactive on 2023-09-15
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.activeRoute.params.pipe(takeUntil(this.onDestroy$)).subscribe((params: { id: string; selectedTab: WorkerDetailsTabType } | any) => {
            if (params?.id) {
                this.navigate.openSidePanel({
                    buttonsData: {
                        spaceBetween: true,
                        buttons: [
                            {
                                text: 'NAROČI PREGLED',
                                onClick: (instance: WorkerDetailsComponent) => {
                                    instance.bookTerm();
                                },
                                buttonClass: 'btn btn-primary',
                                iconClass: 'la-stethoscope',
                                dataTestid: 'btn-book-term',
                            },
                            {
                                text: '',
                                onClick: (instance: WorkerDetailsComponent) => {
                                    instance.deleteEmployee();
                                },
                                buttonClass: 'btn btn-outline-primary',
                                iconClass: 'la-trash h5 mb-0',
                                dataTestid: 'btn-delete-employee',
                            },
                        ],
                    },
                    component: WorkerDetailsComponent,
                    params: {
                        setData: { workerData: { id: params?.id }, selectedTab: params?.selectedTab },
                    },
                    title: '',
                });
            }
        });

        this.navigate.onCloseSidePanel.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            if (this.location.path()?.includes('workers')) this.location.go(`workers`);
            this.filterWorkers(true);
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.navigate.closeSidePanel();
    }

    private initFilterForm() {
        this.filterForm = this.fb.group({
            searchString: '',
        });

        // @TODO: Ignored with eslint-interactive on 2023-09-15
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.filterForm.controls.searchString.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(searchData => {
            this.sortAndPage.currentPage = 0;
            this.sortAndPage.offset = 0;
            this.filterWorkers();
        });
    }

    public onSort(event: { sorts: { dir: string; prop: string }[] }) {
        const { sorts } = event;
        const { prop, dir: orderDirection } = sorts[0];

        const order = this.columnsArray.find(col => col.prop === prop)?.fieldName;

        if (order && orderDirection) {
            this.sortAndPage.order = order;
            this.sortAndPage.orderDirection = orderDirection;
            // Set page and offset to 0 since ngx-datatable switches back to page 1 when sorting
            this.sortAndPage.currentPage = 0;
            this.sortAndPage.offset = 0;
            this.filterWorkers();
        }
    }

    public onPage(event: { count: number; limit: number; offset: number; pageSize: number }) {
        const { pageSize: limit, offset } = event;
        this.sortAndPage.limit = limit;
        this.sortAndPage.offset = offset * limit;
        this.filterWorkers();
    }

    private filterWorkers(forceReload = false) {
        this.loader.setLoading(true);
        this.workerService
            .getEmployees(
                this.employerId,
                {
                    ...this.sortAndPage,
                },
                this.filterForm?.controls?.searchString?.value,
                forceReload,
            )
            .subscribe(
                ({ data: workers, metadata }) => {
                    this.allWorkers = workers;

                    this.sortAndPage.count = metadata.totalCount;
                    this.sortAndPage.currentPage = metadata.currentPage - 1;
                },
                // @TODO: Ignored with eslint-interactive on 2023-09-15
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                err => {
                    this.toast.error('Napaka pri nalaganju delavcev');
                },
                () => {
                    this.loader.setLoading(false);
                },
            );

        this.allWorkers = [...this.allWorkers];
    }

    public workerSelected({ selected }: { selected: Employee[] }) {
        this.navigate.openSidePanel({
            buttonsData: {
                spaceBetween: true,
                buttons: [
                    {
                        text: 'NAROČI PREGLED',
                        buttonClass: 'btn btn-primary',
                        iconClass: 'la-stethoscope',
                        onClick: (instance: WorkerDetailsComponent) => {
                            instance.bookTerm();
                        },
                    },
                    {
                        text: '',
                        onClick: (instance: WorkerDetailsComponent) => {
                            instance.deleteEmployee();
                        },
                        buttonClass: 'btn btn-outline-primary',
                        iconClass: 'la-trash h5 mb-0',
                    },
                ],
            },
            component: WorkerDetailsComponent,
            params: {
                setData: { workerData: selected[0] },
                reloadOnClose: true,
            },
            title: '',
        });
    }

    public addNewWorker() {
        this.navigate.openSidePanel({
            component: WorkerDetailsComponent,
            params: {
                setData: { workerData: undefined, allowedTabs: [WorkerDetailsTabType.WORKER_DATA] },
                reloadOnClose: true,
            },
            title: '',
        });
    }

    public bookWorker(workerData: Employee) {
        this.router.navigate([`booking/single/${workerData.id}`]);
    }
}
