import { Location } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, EMPTY, from, iif, map, Observable, of, shareReplay, Subject, switchMap, takeUntil } from 'rxjs';
import { Employee } from '../../../../_common/model/employee';
import { HelperService } from '../../../../_common/services/helper.service';
import { MfToasterService } from '../../../../_common/services/mf-toaster.service';
import { NavigateService } from '../../../../_common/services/navigate.service';
import { WorkerDetailsTabType } from '../../enums/workerDetailsTabsType.enum';
import { WorkersService } from '../../workers.service';

@Component({
    selector: 'worker-details',
    templateUrl: './worker-details.component.html',
    styleUrls: ['./worker-details.component.scss'],
})
export class WorkerDetailsComponent implements OnDestroy {
    constructor(
        private location: Location,
        private navigateRest: NavigateService,
        private router: Router,
        private workerRest: WorkersService,
        private toastr: MfToasterService,
        private helperService: HelperService,
    ) {}

    private onDestroy$ = new Subject<void>();
    public selectedTab: WorkerDetailsTabType;
    public workerTabs: WorkerDetailsTabType[];
    private workerDataSubject = new BehaviorSubject<Employee | null>(null);
    public workerData$ = this.createWorkerData$();

    @Input() set setData(value: { workerData: Employee; selectedTab: WorkerDetailsTabType; allowedTabs?: WorkerDetailsTabType[] }) {
        this.workerTabs = value.allowedTabs || Object.values(WorkerDetailsTabType);
        this.workerDataSubject.next(value.workerData);
        this.selectTab(value.selectedTab || this.workerTabs[0]);
    }

    ngOnDestroy() {
        this.onDestroy$.next();
    }

    public selectTab(selectedTab: WorkerDetailsTabType) {
        this.workerDataSubject.subscribe(workerData => {
            this.selectedTab = selectedTab;
            if (this.location.path()?.includes('/workers') && workerData?.id) {
                this.location.go(`workers/${workerData.id}/${selectedTab}`);
            }
        });
    }

    public bookTerm() {
        this.workerDataSubject.subscribe(workerData => {
            this.router.navigate([`booking/single/${workerData?.id}`]);
        });
    }

    private createWorkerData$(): Observable<Employee> {
        return this.workerDataSubject.pipe(
            switchMap(workerData =>
                iif(
                    () => workerData == null,
                    of(workerData),
                    this.workerRest.getEmployee(workerData?.id as string).pipe(map(response => response.data)),
                ),
            ),
            catchError(() => {
                this.toastr.error('Napaka pri pridobivanju podatkov o delavcu.');
                return EMPTY;
            }),
            map(result => result as Employee),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    public deleteEmployee(): void {
        this.workerDataSubject
            .pipe(
                switchMap(workerData => {
                    return from(this.helperService.displayAlert('Brisanje delavca!', 'Ali ste prepričani, da želite izbrisati delavca?')).pipe(
                        map(result => ({ workerData, result })),
                    );
                }),
                takeUntil(this.onDestroy$),
            )
            .subscribe(({ workerData, result }) => {
                if (result.value && workerData?.id) {
                    this.workerRest.deleteEmployee(workerData.id).subscribe({
                        next: () => {
                            this.toastr.success('Delavec uspešno izbrisan.');
                            this.navigateRest.closeSidePanel();
                        },
                        error: err => {
                            this.toastr.errorAndSend('Napaka pri brisanju delavca.', err);
                        },
                    });
                }
            });
    }
}
