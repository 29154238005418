import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'valueChecker',
})
export class ValueCheckerPipe implements PipeTransform {
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    transform(value: unknown, ...args: unknown[]): any {
        return value || '(Ni podatka)';
    }
}
