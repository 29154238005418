<div (keyup.arrowright)="activeModal.close('next')" (keyup.arrowleft)="activeModal.close('previous')" class="body">
    <div (click)="activeModal.dismiss('close-no-error'); (false)" class="mf-view-tools">
        <div class="d-flex justify-content-center my-2">
            <input class="d-none" />
            <a
                *ngIf="navigationButtons"
                (click)="activeModal.close('previous'); (false)"
                openDelay="300"
                ngbTooltip="Prejšnji"
                tooltipClass="d-none d-lg-block"
                href="#"
                class="btn btn-primary py-1 px-2"
            >
                <i class="las la-arrow-left"></i>
            </a>
            <a
                *ngIf="documentType === 'application/pdf' && !viewOnly"
                (click)="printFile(); $event.stopPropagation(); (false)"
                openDelay="300"
                ngbTooltip="Natisni dokument"
                tooltipClass="d-none d-lg-block"
                href="#"
                class="btn btn-primary py-1 px-2"
            >
                <i class="las la-print"></i>
            </a>
            <!-- @TODO: Ignored manually on 2023-09-15 -->
            <!-- eslint-disable @angular-eslint/template/eqeqeq -->
            <a
                *ngIf="
                    !(
                        documentType != 'application/pdf' &&
                        !documentType.includes('video') &&
                        documentType != 'text/plain' &&
                        !documentType.includes('image')
                    ) && !viewOnly
                "
                (click)="downloadFile(); $event.stopPropagation(); (false)"
                openDelay="300"
                ngbTooltip="Prenesi dokument"
                tooltipClass="d-none d-lg-block"
                href="#"
                class="btn btn-primary py-1 px-2"
            >
                <i class="las la-download"></i>
            </a>
            <!-- eslint-enable -->
            <a
                *ngIf="documentType === 'application/pdf' || documentType.includes('image')"
                (click)="zoom_in(); $event.stopPropagation(); (false)"
                openDelay="300"
                href="#"
                ngbTooltip="Povečaj"
                tooltipClass="d-none d-lg-block"
                class="btn btn-primary py-1 px-2"
            >
                <i class="las la-plus"></i>
            </a>
            <a
                *ngIf="documentType === 'application/pdf' || documentType.includes('image')"
                (click)="zoom_out(); $event.stopPropagation(); (false)"
                openDelay="300"
                href="#"
                ngbTooltip="Pomanjšaj"
                tooltipClass="d-none d-lg-block"
                class="btn btn-primary py-1 px-2"
            >
                <i class="las la-minus"></i>
            </a>
            <a
                *ngIf="documentType === 'application/pdf' && !fullScreenEnabled"
                (click)="fullscreen(); $event.stopPropagation(); (false)"
                openDelay="300"
                href="#"
                ngbTooltip="Razširi"
                tooltipClass="d-none d-lg-block"
                class="btn btn-primary py-1 px-2"
            >
                <i class="las la-expand"></i>
            </a>
            <a
                *ngIf="documentType === 'application/pdf' && fullScreenEnabled"
                (click)="fullscreen(); $event.stopPropagation(); (false)"
                openDelay="300"
                href="#"
                ngbTooltip="Skrči"
                tooltipClass="d-none d-lg-block"
                class="btn btn-primary py-1 px-2"
            >
                <i class="las la-compress"></i>
            </a>
            <a
                (click)="activeModal.dismiss('close-no-error'); (false)"
                openDelay="300"
                ngbTooltip="Zapri"
                tooltipClass="d-none d-lg-block"
                href="#"
                class="btn btn-primary py-1 px-2"
            >
                <i class="las la-times"></i>
            </a>
            <a
                *ngIf="navigationButtons"
                (click)="activeModal.close('next'); (false)"
                openDelay="300"
                ngbTooltip="Naslednji"
                tooltipClass="d-none d-lg-block"
                href="#"
                class="btn btn-primary py-1 px-2"
            >
                <i class="las la-arrow-right"></i>
            </a>

            <!-- <button
                class="btn btn-accent m-btn m-btn--icon m-btn--pill mt-2 mb-2 "
                *ngIf="signature?.appendTo"
                (click)="appendDocumentToCase()"
            >
                Pripni k avtorizaciji
            </button> -->
        </div>
    </div>

    <!-- IFRAME FOR PRINTING -->
    <iframe #blob frameborder="0" width="0px" height="0px" style="left: -9999px"></iframe>
    <!-- PDF -->
    <div *ngIf="documentType === 'application/pdf'" (click)="clickEvent($event)" id="pdf" style="overflow: auto" class="test">
        <!-- <div class="mf-digital-signature-seal" *ngIf="!checkIfEmpty(signature)">
            <div class="d-flex">
                <div>
                    <i class="la la-shield pt-1" style="height: 30px"></i>
                </div>
                <div>
                    <div>
                        <span>Podpisal: </span> <span class="m--font-boldest">{{ signature?.signer }}</span>
                    </div>
                    <div>
                        <span>Datum: </span> <span class="m--font-boldest">{{ signature?.date }}</span>
                    </div>
                    <div>
                        <span>IZS: </span> <span class="m--font-boldest">{{ signature?.contractor }}</span>
                    </div>
                </div>
            </div>
        </div> -->
        <pdf-viewer
            #medaViewer
            *ngIf="fileReady"
            [src]="encryptedFile ? { url: documentFile | safe: 'url', password: password } : documentFile"
            [fit-to-page]="true"
            [original-size]="false"
            [zoom]="zoom"
            (after-load-complete)="stopLoading($event)"
            (error)="showError($event)"
            class="d-block position-static"
        ></pdf-viewer>
    </div>
    <!-- IMAGE -->
    <div *ngIf="documentType.includes('image')" (click)="clickEvent($event)" class="mf-image-view">
        <img [src]="documentFile | safe: 'url'" [ngStyle]="{ width: widthPic }" id="img" class="center" />
    </div>
    <!-- ENCRYPTED PDF -->
    <div
        *ngIf="failedPassword"
        style="background-color: white; border-radius: 10px"
        class="text-warning text-center p-4 col-12 col-md-6 col-xl-3 ml-auto mr-auto mt-3"
    >
        <div class="m-2">
            <span style="font-size: 150%" i18n="media.view.document.">Dokument je zaklenjen</span>
        </div>
        <div class="m-2">
            <span><i class="la la-lock la-5x"></i></span>
        </div>
        <div>
            <button (click)="reEnterPassword()" class="btn btn-accent m-btn m-btn--pill m-btn--custom m-btn--air">Vnesi geslo</button>
        </div>
    </div>
    <!-- TEXT FILE-->
    <!-- @TODO: Ignored manually on 2023-09-15 -->
    <!-- eslint-disable @angular-eslint/template/eqeqeq -->
    <div *ngIf="documentType == 'text/plain'" (click)="clickEvent($event)" class="mf-text-plain-view p-3 m-1">
        <p>
            {{ documentFile }}
        </p>
    </div>
    <!-- eslint-enable -->
    <!-- VIDEO -->
    <!-- <video #videoPlayer controls preload="auto" class="center" [ngClass]="{ hideVideo: !documentType.includes('video') }">
        <source src="documentFile | safe: 'url'" type="video/mp4" />
        Vaš brskalnik ne podpira ogleda videa
    </video> -->
    <!-- UNKNOWN TYPE -->
    <!-- @TODO: Ignored manually on 2023-09-15 -->
    <!-- eslint-disable @angular-eslint/template/eqeqeq, @angular-eslint/template/conditional-complexity -->
    <div
        *ngIf="
            documentType != 'application/pdf' && !documentType.includes('video') && documentType != 'text/plain' && !documentType.includes('image')
        "
        style="background-color: white; border-radius: 10px"
        class="text-center p-4 col-12 col-md-6 col-xl-3 mx-auto mt-3"
    >
        <!-- eslint-enable -->
        <div class="row">
            <div class="col-12" i18n="@@media.view.unknown.document">Predogled ni mogoč</div>
            <div class="col-6 mt-2 mx-auto" i18n="@@media.view.download.document">
                <button (click)="downloadFile()" class="btn btn-outline-accent m-btn m-btn--pill btn-block">Prenesi datoteko</button>
            </div>
        </div>
    </div>
</div>
