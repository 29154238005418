import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-work-groups-template',
    templateUrl: './work-groups-template.component.html',
    styleUrls: ['./work-groups-template.component.scss'],
})
export class WorkGroupsTemplateComponent implements OnInit {
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit(): void {}
}
