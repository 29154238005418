<div class="mf-men-header position-relative">
    <i (click)="minMenu()" class="mf-min-icon las la-2x" [ngClass]="isMin ? 'la-angle-right' : 'la-angle-left'"></i>

    <div class="mf-men-header_wrapper">
        <div class="d-flex flex-column align-items-center p-3">
            <div class="mf-men-header_logo-max pt-2 pb-4">
                <a [routerLink]="'/index'">
                    <img src="./../../../../../assets/svg/preventivni-logo-2-w.svg" alt="Network" class="mx-auto" />
                </a>
            </div>
            <div class="mf-men-header_logo-min pt-2 pb-4">
                <a [routerLink]="'/index'">
                    <img src="./../../../../../assets/svg/preventivni-logo-2-w.svg" alt="Network" class="mx-auto" />
                </a>
            </div>
            <div style="height: 2px" class="position-relative w-100 my-2 mf-men-header_user-max">
                <div class="mf-dashed w-100"></div>
            </div>
            <div class="mf-men-header_partner">
                <h5 class="font-weight-bold text-center text-uppercase">{{ bs?.name }}</h5>
            </div>
            <div class="mf-men-header_user">
                <div class="mf-men-header_user-max text-center">
                    <div class="font-weight-bold text-uppercase">{{ user?.name }} {{ user?.surname }}</div>
                </div>
                <div class="mf-men-header_user-min">
                    <div class="mf-men-header_user-min_circle">{{ user?.name?.split('')[0] || '' }} {{ user?.surname?.split('')[0] || '' }}</div>
                </div>
            </div>
            <div class="pt-2 mf-men-header_book-max">
                <div [routerLink]="'/booking/single'" class="mf-btn-arrow">NAROČI PREGLED</div>
            </div>
            <div ngbTooltip="Naroči pregled" container="body" class="mf-men-header_book-min">
                <div [routerLink]="'/booking/single'" class="mf-men-header_user-min_button red cursor-pointer">
                    <i class="las la-hospital"></i>
                </div>
            </div>
        </div>
    </div>
</div>
