import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from 'file-saver';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { MfToasterService } from '../../services/mf-toaster.service';
// import * as PDFJS from 'pdfjs-dist';
// PDFJS.GlobalWorkerOptions.workerSrc = 'pdf.worker.js';
@Component({
    selector: 'meda-view',
    templateUrl: './meda-view.component.html',
    styleUrls: ['./meda-view.component.scss'],
})
export class MedaViewComponent {
    @ViewChild('medaViewer') medaViewer: ElementRef;

    @Input()
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set setdocumentFile(data: any) {
        this.documentFile = URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
        this.blobData = data;
        if (data) {
            if (data.type.includes('video')) {
                this.isVideo = true;
                // this.videoPlayer.element.nativeElement.src = this.documentFile;
            } else if (data.type == 'text/plain') {
                const reader = new FileReader();
                // @TODO: Ignored with eslint-interactive on 2023-09-15
                // eslint-disable-next-line @typescript-eslint/no-this-alias
                const that = this;
                reader.onload = function () {
                    that.documentFile = reader.result;
                };
                reader.readAsText(data, 'ISO-8859-1');
            } else if (data.type.includes('pdf') || data.type.includes('application/json')) {
                this.iframe.element.nativeElement.style.display = 'none';
                // this.pdfFullyLoaded = false;
                const reader = new FileReader();
                // @TODO: Ignored with eslint-interactive on 2023-09-15
                // eslint-disable-next-line @typescript-eslint/no-this-alias
                const that = this;
                reader.readAsText(this.blobData, 'UTF-8');
                // @TODO: Ignored with eslint-interactive on 2023-09-15
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                reader.onload = function (evt: { target: any }) {
                    if (evt?.target?.result?.toString()?.includes('Encrypt')) {
                        that.encryptedFile = true;
                        that.displayAlert(' Zaščitena datoteka \n Vnesite geslo', '', that.alertSettings).then(result => {
                            if (result.value) {
                                that.password = result.value;
                                that.fileReady = true;
                                that.iframe.element.nativeElement.src = that.documentFile;
                            } else {
                                that.fileReady = true;
                            }
                        });
                    } else {
                        that.fileReady = true;
                        that.iframe.element.nativeElement.src = that.documentFile;
                    }
                };
            } else {
                //unknown type --> omogoči download
            }
        }
    }
    @Input() viewOnly = true;
    @Input() documentType: string;
    @Input() fileName = '';

    @ViewChild('blob', { read: ViewContainerRef, static: true })
    iframe: ViewContainerRef;

    // @ViewChild('videoPlayer', { read: ViewContainerRef, static: true })
    // videoPlayer: ViewContainerRef;

    // @Input()
    // signature: DocumentSign;

    @Input()
    print = false;

    // @Output() public action: Subject<void> = new Subject();
    public isVideo = false;
    public blobData: Blob;
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public documentFile: any;
    public zoom = 0.6;
    public fullScreenEnabled = false;
    public widthPic: string;
    public width = 50;
    public navigationButtons = false;
    //spremenljivke za zaščitene datoteke
    public encryptedFile = false;
    public password = '';
    public fileReady = false;
    public failedPassword = false;
    public pdfFullyLoaded = false;

    public alertSettings = {
        html: `
    <div class="row">
    <div class="col-12">
    <input type="password" class="form-control m-input mf-password" autocomplete='off'>
    </div>
    </div>`,
        preConfirm: () => {
            const pass = (this.document.querySelector('.mf-password') as HTMLInputElement).value;
            return pass;
        },
    };

    constructor(
        @Inject(DOCUMENT) private document: Document,
        public activeModal: NgbActiveModal,
        private mfToast: MfToasterService,
    ) {}

    // public checkIfEmpty(signature: DocumentSign): boolean {
    //     return _.compact(_.values(signature)).length < 1;
    // }
    //ko klikneš drugam kakor pdf/slika, ti zapre popup
    public clickEvent(event: { srcElement: { className: string } }) {
        if (
            event.srcElement.className === 'textLayer' ||
            event.srcElement.className === 'center' ||
            event.srcElement.className === 'd-flex' ||
            event.srcElement.className === 'm--font-boldest' ||
            event.srcElement.className.includes('mf-text-plain-view') ||
            event.srcElement.className === ''
        ) {
            return;
        } else {
            this.activeModal.dismiss('close-no-error');
        }
    }
    // public nextFile(data: string) {
    //     this.action.next({ action: data, data: this.blobData });
    // }

    public appendDocumentToCase(): void {
        this.activeModal.close({ action: 'appendDocumentToCase' });
    }

    // public test(): void {
    //     console.log('test');
    // }
    public zoom_in() {
        if (this.documentType === 'application/pdf') {
            this.zoom += 0.25;
        }
        if (this.documentType.includes('image')) {
            if (this.width == 100) {
                return;
            }
            this.width += 10;
            this.widthPic = this.width + '%';
        }
    }

    public zoom_out() {
        if (this.documentType === 'application/pdf') {
            if (this.zoom == 0.25) {
                return;
            }
            this.zoom -= 0.25;
        }
        if (this.documentType.includes('image')) {
            if (this.width == 10) {
                return;
            }
            this.width -= 10;
            this.widthPic = this.width + '%';
        }
    }
    public fullscreen() {
        if (this.documentType === 'application/pdf') {
            if (!this.fullScreenEnabled) {
                this.zoom = 1;
                this.fullScreenEnabled = true;
            } else {
                this.zoom = 0.6;
                this.fullScreenEnabled = false;
            }
        }
    }

    public downloadFile(): void {
        FileSaver.saveAs(this.blobData, this.fileName);
    }

    public printFile(): void {
        if (this.encryptedFile) {
            this.mfToast.warning('Tiskanje zaščitenih datotek ni dovoljeno.');
            this.downloadFile();
        } else {
            if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
                //Firefox
                window.location.href = this.documentFile;
            } else {
                //Chrome
                this.iframe.element.nativeElement.contentWindow.focus();
                this.iframe.element.nativeElement.contentWindow.print();
            }
        }
    }

    public stopLoading(data: { _pdfInfo: { numPages: number } }): void {
        // (this.document.querySelector('pdf-viewer') as HTMLInputElement).style.height = `${(data._pdfInfo.numPages || 0) * 1000}px`;
        // (this.medaViewer as any).element.nativeElement.style.height = `${(data._pdfInfo.numPages || 0) * 1000}px`;
    }

    public showError(event: { name: string }) {
        if (event?.name === 'PasswordException') {
            this.failedPassword = true;
            this.fileReady = false;
        }
    }
    public reEnterPassword() {
        this.displayAlert(' Zaščitena datoteka \n Vnesite geslo', '', this.alertSettings).then((result: { value: string }) => {
            if (result.value) {
                this.failedPassword = false;
                this.fileReady = true;
                this.password = result.value;
                this.iframe.element.nativeElement.src = this.documentFile;
            }
        });
    }

    public pdfLoadingComplete(): void {
        this.pdfFullyLoaded = true;
        if (this.print) {
            // @TODO: Ignored with eslint-interactive on 2023-09-15
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const that = this;
            setTimeout(function () {
                that.printFile();
            }, 1500);
        }
    }

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public displayAlert(title: string, content: string, settings?: SweetAlertOptions): Promise<any> {
        return Swal.fire(
            Object.assign(
                {
                    title: title,
                    text: content,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'POTRDI',
                    cancelButtonText: 'PREKLIČI',
                    reverseButtons: true,
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-primary ml-2 m-btn m-btn--pill',
                    confirmButtonColor: null,
                    cancelButtonClass: 'btn btn-accent m-btn m-btn--pill',
                    cancelButtonColor: null,
                },
                settings,
            ),
        );
    }
}
