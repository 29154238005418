import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ReservationDetailsTabType } from './../enum/resDetailsTabsType.enum';
import { ReservationDetails } from './../model/reservation-details';

@Component({
    selector: 'res-reservation-details',
    templateUrl: './res-reservation-details.component.html',
    styleUrls: ['./res-reservation-details.component.scss'],
})
export class ResReservationDetailsComponent implements OnInit {
    public reservationDetails: ReservationDetails;
    public selectedTab: ReservationDetailsTabType;
    public reservationsTabs: ReservationDetailsTabType[] = Object.values(ReservationDetailsTabType);

    constructor(private location: Location) {}

    @Input() set setData(value: { reservationDetails: ReservationDetails; selectedTab: ReservationDetailsTabType }) {
        this.reservationDetails = value.reservationDetails;
        this.selectTab(value.selectedTab || ReservationDetailsTabType.STATUS);
    }
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit() {}
    public selectTab(selectedTab: ReservationDetailsTabType) {
        this.selectedTab = selectedTab;
        if (this.location.path()?.includes('/reservations-list/reservation'))
            this.location.go(`reservations-list/reservation/${this.reservationDetails.caseId}/${selectedTab}`);
    }
}
