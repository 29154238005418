import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { PipesModule } from '../../_common/pipes/pipes.module';
import { ComDateInputComponent } from './com-date-input/com-date-input.component';
import { ComInputComponent } from './com-input/com-input.component';
import { ComTextareaComponent } from './com-textarea/com-textarea.component';
import { ComYearMonthSelectComponent } from './com-year-month-select/com-year-month-select.component';
import { ReferralTypeFormComponent } from './referral-type-form/referral-type-form.component';
import { TimeSlotDetailComponent } from './time-slot-detail/time-slot-detail.component';
import { TimeSlotTableComponent } from './time-slot-table/time-slot-table.component';
import { TimeSlotsComponent } from './time-slots/time-slots.component';

@NgModule({
    declarations: [
        ComTextareaComponent,
        ComInputComponent,
        ComDateInputComponent,
        ComYearMonthSelectComponent,
        TimeSlotTableComponent,
        ReferralTypeFormComponent,
        TimeSlotDetailComponent,
        TimeSlotsComponent,
    ],
    imports: [CommonModule, NgbModule, ReactiveFormsModule, FormsModule, NgSelectModule, FormsModule, ReactiveFormsModule, PipesModule],
    exports: [
        ComTextareaComponent,
        ComInputComponent,
        ComDateInputComponent,
        ComYearMonthSelectComponent,
        TimeSlotTableComponent,
        ReferralTypeFormComponent,
        TimeSlotDetailComponent,
        TimeSlotsComponent,
    ],
})
export class SharedModule {}
