// @TODO: Ignored with eslint-interactive on 2023-09-15
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Injectable } from '@angular/core';
// @TODO: Ignored with eslint-interactive on 2023-09-15
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
    readonly DELIMITER = '.';
    // readonly DELIMITER_MODEL = '-';

    fromModel(value: string | null): NgbDateStruct | null {
        let date = null;
        if (value) {
            if (value?.slice(0, 10).match(/^\d{4}-\d{2}-\d{2}$/) !== null) {
                //2022-07-12
                date = value.split('-');
                return {
                    day: parseInt(date[2], 10),
                    month: parseInt(date[1], 10),
                    year: parseInt(date[0], 10),
                };
            } else {
                //12.07.2022
                date = value.split(this.DELIMITER);
                return {
                    day: parseInt(date[0], 10),
                    month: parseInt(date[1], 10),
                    year: parseInt(date[2], 10),
                };
            }
        }
        return date;
    }

    toModel(date: NgbDateStruct | null): string | null {
        // return date ? date.year + this.DELIMITER_MODEL + date.month + this.DELIMITER_MODEL + date.day : null;
        return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
    }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
    readonly DELIMITER = '.';

    parse(value: string): NgbDateStruct | null {
        if (value) {
            const date = value.split(this.DELIMITER);
            return {
                day: parseInt(date[0], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[2], 10),
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
    }
}
