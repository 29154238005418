import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BookingTypePipe } from './booking-type.pipe';
import { InDaysPipe } from './in-days.pipe';
import { ReferralStringPipe } from './referral-string.pipe';
import { SafePipe } from './safe.pipe';
import { TabTypeStringPipe } from './tabType-string.pipe';
import { TranslateBoolean } from './translate-boolean.pipe';
import { ValueCheckerPipe } from './value-checker.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [SafePipe, TranslateBoolean, ValueCheckerPipe, ReferralStringPipe, TabTypeStringPipe, BookingTypePipe, InDaysPipe],
    providers: [],
    exports: [SafePipe, TranslateBoolean, ValueCheckerPipe, ReferralStringPipe, TabTypeStringPipe, BookingTypePipe, InDaysPipe],
})
export class PipesModule {}
