import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from './../../../../environments/environment';
import { ComPopupHostDirective } from './com-popup-host.directive';

@Component({
    selector: 'com-base-popup',
    templateUrl: './com-base-popup.component.html',
    styleUrls: ['./com-base-popup.component.scss'],
})
export class ComBasePopupComponent implements OnInit {
    @Input()
    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public component: any;
    @Input()
    public settings: { hiddePolicy: boolean };

    // @TODO: Ignored with eslint-interactive on 2023-09-15
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public env: any = environment;

    @ViewChild(ComPopupHostDirective, { static: true }) comHost?: ComPopupHostDirective;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        public activeModal: NgbActiveModal,
    ) {}

    ngOnInit() {
        this.loadComponent();
    }

    loadComponent() {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.component);
        const viewContainerRef = this.comHost?.viewContainerRef;
        viewContainerRef?.clear();
        // @TODO: Ignored with eslint-interactive on 2023-09-15
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const componentRef = viewContainerRef?.createComponent<any>(componentFactory);
        componentRef?.instance.onClosePopup.subscribe(() => {
            this.activeModal.close('Close click');
        });
    }
}
