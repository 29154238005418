import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_common/guards/auth.guard';
import { IndexComponent } from './_modules/index/index.component';
import { ThmIndexMenuComponent } from './_theme/thm-index-menu/thm-index-menu.component';

const routes: Routes = [
    {
        path: 'public',
        loadChildren: () => import('./_modules/public/public.module').then(m => m.PublicModule),
    },
    { path: '', redirectTo: 'index', pathMatch: 'full' },
    {
        path: '',
        component: ThmIndexMenuComponent,
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'index',
                loadChildren: () => import('./_modules/index/index.module').then(m => m.IndexModule),
                component: IndexComponent,
            },
            {
                path: 'settings',
                loadChildren: () => import('./_modules/settings/settings.module').then(m => m.SettingsModule),
            },
            {
                path: 'booking',
                loadChildren: () => import('./_modules/booking/booking.module').then(m => m.BookingModule),
            },
            {
                path: 'slots',
                loadChildren: () => import('./_modules/slots/slots.module').then(m => m.SlotsModule),
            },
            {
                path: 'reservations-list',
                loadChildren: () => import('./_modules/reservations/reservations.module').then(m => m.ReservationsModule),
            },
            {
                path: 'workers',
                loadChildren: () => import('./_modules/workers/workers.module').then(m => m.WorkersModule),
            },
            {
                path: 'working-groups',
                loadChildren: () => import('./_modules/working-groups/working-groups.module').then(m => m.WorkingGroupsModule),
            },
            {
                path: 'statistics',
                loadChildren: () => import('./_modules/statistics/statistics.module').then(m => m.StatisticsModule),
            },
            {
                path: 'analytics',
                loadChildren: () => import('./_modules/analytics/analytics.module').then(m => m.AnalyticsModule),
            },
            {
                path: 'contractor-network',
                loadChildren: () => import('./_modules/contractor-network/contractor-network.module').then(m => m.ContractorNetworkModule),
            },
        ],
    },
    {
        path: '',
        children: [
            {
                path: '',
                loadChildren: () => import('./_modules/layout/layout.module').then(m => m.LayoutModule),
                component: IndexComponent,
            },
        ],
    },
    { path: '**', redirectTo: '/index' },
    // { path: '', redirectTo: 'public/login', pathMatch: 'full' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'top',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
